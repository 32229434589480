import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GlameraSharedService } from 'glamera-shared';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PlansService {
  private companyId = 0;
 

  get apiUrl():string {
    return `${environment.apiUrl}/erp/web/system/packages/features`;
  }

  
  get nonSubscribedBranchesApiUrl():string {
    return  `${environment.apiUrl}/erp/web/company/${this.companyId}/subscriptions/nonSubscripedBranches`;
  }

  get subscriptionPlansApiUrl():string {
    return `${environment.apiUrl}/erp/web/company/${this.companyId}/subscriptions`;
  }

  get subscriptionUrl():string {
    return  `${environment.apiUrl}/erp/web/company/${this.companyId}/subscriptions`;
  }
 
 

  get token ():string {
    return  this.sharedService.token ??"";
  }
 
  constructor(private http: HttpClient ,
    private sharedService: GlameraSharedService 
  ) {
    this.companyId=sharedService.company.id;  
   }


  getPlans(): Observable<any> {
    const token =this.token;
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    return this.http.get<any>(this.apiUrl, { headers });
  }

  getNonSubscribedBranches(): Observable<any> {
    const token =this.token;
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    return this.http.get<any>(this.nonSubscribedBranchesApiUrl, { headers });
  }

    getSubscriptionPlans(): Observable<any> {
      const token =this.token;
      const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    return this.http.get<any>(this.subscriptionPlansApiUrl, { headers });
  }

    subscribeToPlan(subscriptionData: any): Observable<any> {
      const token =this.token;
      const headers = new HttpHeaders({
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
        'AppId': '7',
        'lang': 'ar'
      });

    return this.http.post<any>(this.subscriptionUrl, subscriptionData, {
      headers,
    });
  }

  upgradeSubscription(subscriptionData: any): Observable<any> {
    const token =this.token;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
      'AppId': '7',
      'lang': 'ar'
    });

  return this.http.post<any>(this.subscriptionUrl+"/upgrade", subscriptionData, {
    headers,
  });
}


  getSubscriptionDetails(): Observable<any> {
    
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`);

    return this.http.get<any>(this.subscriptionPlansApiUrl, { headers });
  }

  checkSubscriptionChange(subscriptionData:any): Observable<any> { 
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`); 
    return this.http.get<any>(this.subscriptionUrl+"/change/check", { headers,params:subscriptionData });
  }




}
