import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TableResult } from '@s-app/common/models';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  public constructor(private http: HttpClient) {}

  public subsciptionDetails(): Observable<TableResult<any>> {
    return this.http.get<TableResult<any>>(
      `${environment.apiUrl}/Subscription/GetMySubsciptionDetails`
    );
  }
}
