import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TableResult } from '@s-app/common/models';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CurrencyService {
  public constructor(private http: HttpClient) {}

  public list(
    isPaginated: boolean = false,
    page: number = 0,
    limit: number = 10,
    search: string = ''
  ): Observable<TableResult<any>> {
    const params = new HttpParams()
      .append('PagingEnabled', isPaginated)
      .append('PageNumber', page)
      .append('PageSize', limit)
      .append('FreeText', search);
    return this.http.get<TableResult<any>>(
      `${environment.apiUrl}/SubscriptionPlan/GetCurrencies`,
      { params }
    );
  }
}
