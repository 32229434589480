<div class="relative min-h-full pb-16">
  <div class="mb-10">
    <span class="font-bold">
      {{ 'translate_your_payment_methods' | translate }}
    </span>
  </div>
  <!-- <div class="grid gap-2 mb-">
    <label *ngFor="let method of paymentMethods" class="rounded-xl bg-white/95 border border-gray-300 p-4 cursor-pointer
    [&:has(input:checked)]:bg-teal-100/50
    [&:has(input:checked)_.checked-icon]:inline [&:has(input:checked)_.unchecked-icon]:hidden">
      <input type="radio" name="method" [value]="method.Id" class="hidden" />
      <div class="flex">
        <div>
          <div *ngIf="method.type === undefined"
            class="border-[1px] border-gray-300 bg-white rounded flex align-items-center w-[48px] h-[48px]">
            <i class="fa-regular fa-credit-card fa-fx fa-2x block m-auto text-gray-600"></i>
          </div>
          <img *ngIf="method.type !== undefined" [src]="'assets/img/icons/payment-methods/' + method.type + '.png'"
            height="48" width="48" />
        </div>
        <div class="flex-1 ltr:ml-3 rtl:mr-3">
          <div class="flex text-gray-700 font-bold">
            <div class="flex-1">
              {{ method.typeName }}
              {{ 'translate_ending_with' | translate }}
              {{ method.CardNumber | ending:3 }}
            </div>
            <i class="unchecked-icon fa-regular fa-circle fa-lg text-teal-500"></i>
            <i class="checked-icon fa-solid hidden fa-check-circle fa-lg text-teal-500"></i>
          </div>
          <div class="text-gray-400 text-sm">
            {{ 'translate_expiry' | translate }}
            {{ method.ExpirationDate }}
          </div>
          <div class="mt-2 text-xs">
            <button (click)="deleteMethod(method)" class="text-red-500 hover:text-red-600 me-2">
              {{ 'translate_delete' | translate }}
            </button>
            <button (click)="showEditMethodDialog(method)" class="text-teal-500 hover:text-teal-600">
              {{ 'translate_edit' | translate }}
            </button>
          </div>
        </div>
      </div>
    </label>
    <div>
      <span (click)="showDifferentMethodDialog()" class="text-sm text-sky-500 hover:text-sky-600 cursor-pointer my-3">
        <i class="fa-solid fa-plus fa-fw me-2"></i>
        {{ 'translate_pay_with_different_method' | translate }}
      </span>
    </div>
  </div> -->
  <div class="border-t-2 border-gray-300 py-6 px-10 text-end absolute bottom-0 -left-10 -right-10 -mb-10">
    <button (click)="hideDialog()"
      class="py-2 px-3 min-w-28 border-1 text-gray-500 hover:text-primary-800 border-primary-300 hover:border-primary-700 rounded-md me-2">
      {{ 'translate_cancel' | translate }}
    </button>
    <button
    (click)="sendPayment()"
      class="py-2 px-3 min-w-28 border-1 text-white bg-primary-700 hover:bg-primary-800 border-primary-300 rounded-md">
      {{ 'translate_pay' | translate }}
    </button>
  </div>
</div>
