import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { BillingService } from '@bl-src/app/core/services/billing.service';
import { TranslateModule } from '@ngx-translate/core';
import { environment } from 'environments/environment';
import { map, Observable } from 'rxjs';
@Component({
  selector: 'app-payment-done',
  standalone: true,
  imports: [CommonModule,TranslateModule],
  templateUrl: './payment-done.component.html',
  styleUrls: ['./payment-done.component.scss'] ,

})
export class PaymentDoneComponent implements OnInit {
  paymentRefernce: any;
  message: string ;
  

  status: 'success' | 'error' | 'pending'='pending';
  paymentstatus: 'success' | 'error' ;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private billingService: BillingService
  ) {

    this.paymentRefernce = this.route.snapshot.params.id;
    console.log('Payment Reference:', this.paymentRefernce);
    this.message = this.route.snapshot.queryParams.message;

    this.paymentstatus = this.route.snapshot.queryParams.paymentstatus ?? 'error';

    if(this.paymentstatus === 'error') {
      this.status = 'error';
    }
    
  }

  ngOnInit(): void { 
    if(this.paymentstatus === 'success') {
      this.confirmPayment();
    }
  }
  
confirmPayment() {
  this.route.paramMap.subscribe((params) => { 
    if (this.paymentRefernce) {
      this.billingService.sendPaymentReference(this.paymentRefernce).subscribe(response => {
        console.log('Response from API:', response);
        this.status = 'success';
        this.startCountdownAndRedirect();
      }, error => {
        this.status = 'error';
        console.error('Error from API:', error);
      });
    }
  }); 
}

  goToSubscriptions(): void {
    this.clearCountdownAndRedirect();
    this.router.navigate(['/subscription']);
  }
  goToInvoicesPage() {
    this.clearCountdownAndRedirect();
    this.router.navigate(['/invoices']);
  }

  redirectToGlamera() {
    this.clearCountdownAndRedirect();
    window.location.href = environment.saasURL;

  }


  clearCountdownAndRedirect(): void {

    clearInterval(this.intervalId);
    clearTimeout(this.timeoutId);
  }
  countdown: number = 10;
  intervalId:any ;
  timeoutId:any ;
  startCountdownAndRedirect(): void {


    const interval = setInterval(() => {
      this.countdown--;
      if (this.countdown === 0) {
      clearInterval(interval);
      }
    }, 1000);

    const timeout = setTimeout(() => {
      this.router.navigate(['/invoices']);
    }, 10000);

    // Store the interval and timeout IDs so they can be cleared later
    this.intervalId = interval;
    this.timeoutId = timeout;
    
  }
}
