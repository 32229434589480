import { Component, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { PaymentMethod } from '@s-app/common/models';
import { SideDialogService } from '@s-app/common/services';
import { PaymentCardService } from '@s-app/core/services/api';
import { PaymentCardCacheService } from '@s-app/core/services/cache';
import { error } from 'console';
import Swal from 'sweetalert2';

@Component({
  templateUrl: './edit.component.html',
})
export class EditComponent implements OnInit {
  public mode: 'edit' | 'new' | 'different' = 'edit';

  public paymentMethod: PaymentMethod | null;

  public form: FormGroup;

  public isSubmitting: boolean = false;

  errorMessage="";

  public constructor(
    private sideDialogService: SideDialogService,
    private paymentCardService: PaymentCardService,
    private paymentCardCacheService: PaymentCardCacheService,
    private translateService: TranslateService
  ) {}

  public ngOnInit(): void {
    this.initForm();

  }

  public showPayDialog(): void {
    this.sideDialogService.showPayDialog();
  }

  public hideDialog(): void {
    this.sideDialogService.showDialogSubject.next(false);
  }

  public submit(): void {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.isSubmitting = true;
      const methodData: PaymentMethod = {
        // BranchId: 13166,
        HolderName: this.form.get('HolderName')?.value,
        email: this.form.get('email')?.value,
        CardNumber: this.form.get('CardNumber')?.value,
        ExpireMonth: this.form.get('expiration-date')?.value.slice(0, 2).toString(),
        ExpireYear: parseInt('20' + this.form.get('expiration-date')?.value.slice(-2).toString()),
        CVV: this.form.get('CVV')?.value,
        // IsDefault: true,
      };

      if (this.mode === 'new') {
        this.paymentCardService
          .create(methodData)
          .subscribe((response: any) => {
            if (response) {
              this.paymentCardCacheService.refresh();
              this.sideDialogService;
              this.hideDialog();
              Swal.fire({
                icon: 'success',
                text: this.translateService.instant(
                  'translate_card_added_successfully'
                ),
                showConfirmButton: false,
                position: 'bottom-start',
                width: '20rem',
                timer: 1000,
              });
              this.isSubmitting = false;
              this.errorMessage = "";
            }
          },(error)  => {
            console.log(error.error.message);
            this.errorMessage = error.error.message;

          });
      } else if (this.mode === 'edit' && this.paymentMethod?.Id) {
        methodData.Id = this.paymentMethod.Id;
        this.paymentCardService
          .update( methodData.Id,methodData)
          .subscribe((response: any) => {
            if (response.Result) {
              this.paymentCardCacheService.refresh();
              this.sideDialogService;
              this.hideDialog();
              Swal.fire({
                icon: 'success',
                text: this.translateService.instant(
                  'translate_card_updated_successfully'
                ),
                showConfirmButton: false,
                position: 'bottom-start',
                width: '20rem',
                timer: 1000,
              });
              this.isSubmitting = false;
              this.errorMessage = "";
            }
          } ,(error)  => {
            console.log(error.error.message);
            this.errorMessage = error.error.message;

          });
      } else if (this.mode === 'different') {
      }
    }
  }

  private initForm(): void {

    console.log(this.paymentMethod );
    this.form = new FormGroup({
      HolderName: new FormControl(
        this.mode === 'edit' ? this.paymentMethod?.HolderName : null,
        Validators.required
      ),
      'expiration-date': new FormControl(
        this.mode === 'edit' &&
        this.paymentMethod?.ExpireMonth &&
        this.paymentMethod?.ExpireMonth
          ? (this.paymentMethod?.ExpireMonth > 9
              ? this.paymentMethod?.ExpireMonth
              : '0' + this.paymentMethod?.ExpireMonth
            ).toString() + this.paymentMethod?.ExpireYear.toString().slice(-2)
          : null,
        [
          Validators.required,
          Validators.pattern('((0[1-9])|(1[0-2]))([0-9]{2})'),
        ]
      ),
      CardNumber: new FormControl(
        this.mode === 'edit' ? this.paymentMethod?.CardNumber : null,
        [Validators.required, Validators.pattern('[0-9]{16}')]
      ),
      CVV: new FormControl(
        this.mode === 'edit' ? this.paymentMethod?.CVV : null,
        [Validators.required, Validators.pattern('[0-9]{3}')]
      ),
      // email: new FormControl(
      //   this.mode === 'edit' ? this.paymentMethod?.email : null,
      //   [Validators.required, Validators.email]
      // ),
      save: new FormControl(null),
    });
  }
}
