<div class="mb-28">
  <div class="my-3 font-semibold text-3xl text-gray-800">
    {{ 'translate_contact_us' | translate }}
  </div>
  <div class="mb-14 text-gray-400">
    {{ 'translate_contact_us_subtitle' | translate }}
  </div>
</div>
<div class="mb-28">
  <div class="mb-8 font-semibold text-3xl text-gray-800">
    {{ 'translate_how_to_reach_us' | translate }}
  </div>
  <div class="grid md:grid-cols-3 gap-10">
    <div>
      <div class="mb-3 font-semibold text-xl text-gray-800">
        {{ 'translate_email' | translate }}
      </div>
      <div class="mb-2 text-gray-400">
        {{ 'translate_contact_us_email_subtext' | translate }}
      </div>
      <a [href]="'mailto:' + 'info@glamera.com'" class="text-lg text-sky-500 underline">
        {{ 'info@glamera.com' }}
      </a>
    </div>
    <div>
      <div class="mb-3 font-semibold text-xl text-gray-800">
        {{ 'translate_phone_number' | translate }}
      </div>
      <div class="mb-2 text-gray-400">
        {{ 'translate_contact_us_phone_subtext' | translate }}
      </div>
      <a [href]="'(+966) 555055515'" class="text-lg text-sky-500 underline">
        <bdi>
          {{ '(+966) 555055515' }}
        </bdi>
      </a>
    </div>
    <div>
      <div class="mb-3 font-semibold text-xl text-gray-800">
        {{ 'translate_online_form' | translate }}
      </div>
      <div class="mb-2 text-gray-400">
        {{ 'translate_contact_us_online_form_subtext' | translate }}
      </div>
      <a href="#" class="text-lg text-sky-500 underline">
        {{ 'translate_form_here' | translate }}
      </a>
    </div>
  </div>
</div>
<div class="mb-28">
  <div class="mb-8 font-semibold text-3xl text-gray-800">
    {{ 'translate_locations' | translate }}
  </div>
  <div class="grid md:grid-cols-2 gap-10">
    <div>
      <div class="mb-3 font-semibold text-xl text-gray-800">
        {{ 'translate_contact_us_location1_name' | translate }}
      </div>
      <div class="mb-2 text-gray-400">
        {{ 'translate_contact_us_location1_address' | translate }}
      </div>
      <a href="#" class="mb-3 inline-block text-lg text-sky-500 underline">
        {{ 'translate_find_our_location' | translate }}
      </a>
      <div class="mb-2 text-gray-700">
        {{ 'translate_working_hours' | translate }} :
      </div>
      <div class="mb-2 text-gray-400">
        {{ 'translate_contact_us_location1_working_hours1' | translate }}
        <br>
        {{ 'translate_contact_us_location1_working_hours2' | translate }}
      </div>
      <div class="mb-2 text-gray-700">
        {{ 'translate_phone_number' | translate }} :
      </div>
      <div class="mb-2 text-gray-400">
        <bdi>
          (+966) 555055515
        </bdi>
      </div>
    </div>
    <div>
      <div class="mb-3 font-semibold text-xl text-gray-800">
        {{ 'translate_contact_us_location2_name' | translate }}
      </div>
      <div class="mb-2 text-gray-400">
        {{ 'translate_contact_us_location2_address' | translate }}
      </div>
      <a href="#" class="mb-3 inline-block text-lg text-sky-500 underline">
        {{ 'translate_find_our_location' | translate }}
      </a>
      <div class="mb-2 text-gray-700">
        {{ 'translate_working_hours' | translate }} :
      </div>
      <div class="mb-2 text-gray-400">
        {{ 'translate_contact_us_location2_working_hours1' | translate }}
        <br>
        {{ 'translate_contact_us_location2_working_hours2' | translate }}
      </div>
      <div class="mb-2 text-gray-700">
        {{ 'translate_phone_number' | translate }} :
      </div>
      <div class="mb-2 text-gray-400">
        <bdi>
          (+20) 1022274148
        </bdi>
      </div>
    </div>
  </div>
</div>
<div class="flex border border-gray-200 mb-28">
  <div class="flex fa-2x text-gray-500 align-items-end bg-gray-200 p-4">
    <div class="flex flex-col gap-4">
      <a href="#" target="_blank">
        <i class="fa-brands fa-facebook-f fa-fw"></i>
      </a>
      <a href="#" target="_blank">
        <i class="fa-brands fa-x-twitter fa-fw"></i>
      </a>
      <a href="#" target="_blank">
        <i class="fa-brands fa-instagram fa-fw"></i>
      </a>
      <a href="#" target="_blank">
        <i class="fa-brands fa-linkedin fa-fw"></i>
      </a>
    </div>
  </div>
  <div class="flex-1 my-8 md:mx-12 px-3">
    <div class="my-3 font-semibold text-3xl text-gray-800">
      {{ 'translate_we_value_your_feedback' | translate  }}
    </div>
    <div class="mb-14 text-gray-400">
      {{ 'translate_contact_us_we_value_your_feedback_subtext' | translate  }}
    </div>
    <form [formGroup]="contactForm">
      <div class="grid gap-4 md:grid-cols-2">
        <div class="mb-3 pb-3 text-gray-800 text-sm font-semibold relative">
          <label for="name" class="mb-2">
            {{ 'translate_name' | translate }}
          </label>
          <input type="text" id="name"
            class="w-full rounded-md border-gray-300 hover:border-primary-700 focus-within:border-primary-700"
            [placeholder]="'translate_enter_name' | translate " formControlName="name" />
          <div *ngIf="contactForm.get('name')?.touched && contactForm.get('name')?.invalid"
            class="text-red-500 text-xs font-normal absolute mt-1">Error</div>
        </div>
        <div class="mb-3 pb-3 text-gray-800 text-sm font-semibold relative">
          <label for="email" class="mb-2">
            {{ 'translate_email' | translate }}
          </label>
          <input type="email" id="email"
            class="w-full rounded-md border-gray-300 hover:border-primary-700 focus-within:border-primary-700"
            [placeholder]="'translate_enter_email' | translate " formControlName="email" />
          <div *ngIf="contactForm.get('email')?.touched && contactForm.get('email')?.invalid"
            class="text-red-500 text-xs font-normal absolute mt-1">Error</div>
        </div>
      </div>
      <div class="mb-3 pb-3 text-gray-800 text-sm font-semibold relative">
        <label for="phone" class="mb-2">
          {{ 'translate_phone_number' | translate }}
        </label>
        <ngx-intl-tel-input class="block [&_.iti]:w-full"
          [cssClass]="'rounded-md w-full border-gray-300 hover:border-primary-700 focus-within:border-primary-700'"
          [preferredCountries]="preferredCountries" [enableAutoCountrySelect]="true" [enablePlaceholder]="true"
          [searchCountryFlag]="true" [separateDialCode]="true" [searchCountryField]="searchCountryField"
          [selectFirstCountry]="false" [selectedCountryISO]="selectedCountryISO" [maxLength]="15"
          [phoneValidation]="true" [inputId]="'phone'" formControlName="phone"></ngx-intl-tel-input>
        <div *ngIf="contactForm.get('phone')?.touched && contactForm.get('phone')?.invalid"
          class="text-red-500 text-xs font-normal absolute mt-1">Error</div>
      </div>
      <div class="mb-3 pb-3 text-gray-800 text-sm font-semibold relative">
        <label for="message" class="mb-2">
          {{ 'translate_message' | translate }}
        </label>
        <textarea type="text" id="message"
          class="w-full rounded-md border-gray-300 hover:border-primary-700 focus-within:border-primary-700"
          maxlength="250" formControlName="message" #message [placeholder]="'translate_write_here' | translate"></textarea>
        <div class="text-gray-400 text-xs">{{ message.value.length }}/250</div>
        <div *ngIf="contactForm.get('message')?.touched && contactForm.get('message')?.invalid"
          class="text-red-500 text-xs font-normal absolute mt-1">Error</div>
      </div>
      <button type="submit"
        class="inline-block ltr:float-right rtl:float-left bg-primary-700 hover:bg-primary-800 text-gray-200 hover:text-gray-50 rounded-md py-2 px-12">
        {{ 'translate_send' | translate }}
      </button>
    </form>
  </div>
</div>
<div class="mx-auto text-center text-gray-500 text-sm">
  <div>
    {{ 'translate_thank_you_for_choosing' | translate }}
    <a [routerLink]="['']" class="text-primary-700 hover:text-primary-800 font-medium">
      {{ 'translate_app_title' | translate }}
    </a>
    .
  </div>
  <div>
    {{ 'translate_contact_us_footer' | translate }}
  </div>
</div>