import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { AppComponent } from './app.component';
import { SubscriptionComponent } from './subscriptions/subscriptions.component';
import { BranchesComponent } from './subscriptions/branches/branches.component';
import { ShowBranchComponent } from './subscriptions/branches/show/show.component';
import { InvoicesComponent } from './subscriptions/invoices/invoices.component';
import { PaymentsComponent } from './subscriptions/payments/payments.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { PlansComponent } from './plans/plans.component';
import { EditComponent } from './subscriptions/payments/methods/edit/edit.component';
import { PayComponent } from './subscriptions/payments/methods/pay/pay.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { EndingPipe } from './core/pipes';
import { FormGridSpinnerComponent } from '@s-app/common/components/grid-spinner/form-grid-spinner.component';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuthInterceptor, LangInterceptor } from '@s-app/core/interceptors';
import { BranchAddonsComponent } from './subscriptions/branches/branch-addons/branch-addons.component';
import { DialogComponent } from './common/components/center-dialog/dialog.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}

@NgModule({ declarations: [
        AppComponent,
        SubscriptionComponent,
        BranchesComponent,
        ShowBranchComponent,
        BranchAddonsComponent,
        InvoicesComponent,
        PaymentsComponent,
        PlansComponent,
        ContactUsComponent,
        EditComponent,
        PayComponent,
        EndingPipe,
        
        
    ],
 
    bootstrap: [AppComponent], 
    imports: [FormsModule,
      FormGridSpinnerComponent,
      DialogComponent,
        ReactiveFormsModule,
        BrowserModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        AppRoutingModule,
        BrowserAnimationsModule,
        NgbModule,
        SweetAlert2Module.forRoot(),
        NgxIntlTelInputModule,
        NgxMaskDirective,
        NgxMaskPipe], providers: [
        provideNgxMask(),
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LangInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {
  constructor(
    private translateService: TranslateService,
    private titleService: Title
  ) {
    const appLang = localStorage.getItem('lang') ?? 'en';
    this.translateService.setDefaultLang('en');
    this.translateService.use(appLang);
    this.translateService.onLangChange.subscribe((event) => {
      document.documentElement.dir = event.lang === 'ar' ? 'rtl' : 'ltr';
      document.documentElement.lang = event.lang;
      localStorage.setItem('lang', event.lang);
      const translatedTitle = this.translateService.instant(
        'translate_app_title'
      );
      this.titleService.setTitle(translatedTitle);
    });
  }
}
