<div class="font-medium my-3">
  <span class="text-gray-400">
    <a [routerLink]="['', 'subscription']">
      {{ "translate_subscription" | translate }}
    </a>
    <i class="fa-solid fa-chevron-right text-xs rtl:rotate-180 mx-2"></i>
  </span>

</div>

<div *ngIf="branch">
  <div class="flex mt-6 mb-2">
    <div class="flex-1 text-gray-800 text-2xl font-semibold">
      {{ branch.name }}
    </div>
    <div class="text-sm text-white font-normal text-end">
      <button class="inline-block bg-red-500 hover:bg-red-700 rounded-full px-3 py-2 mb-2 me-2">
        <i class="fa-solid fa-ban me-2"></i>
        {{ "translate_deactivate_branch" | translate }}
      </button>
      <a [routerLink]="[
          '',
          'subscription',
          'branches',
          branch.subscription.subscriptionId,
          'addons'
        ]" class="inline-block bg-primary-500 hover:bg-primary-700 rounded-full px-3 py-2 mb-2">
        <i class="fa-solid fa-shop me-2"></i>
        {{ "translate_marketplace_addons" | translate }}
      </a>
    </div>
  </div>
  <div class="text-xs text-gray-400 mb-6">
    {{ "" | translate }}
  </div>
  <div class="grid gap-4 md:grid-cols-2 mb-10">
    <div class="rounded-2xl bg-white shadow">
      <div class="pt-6 px-4">
        <div class="flex">
          <div class="flex-1">
            <div class="font-semibold mb-1">
              {{ branch.subscription.planName }}
            </div>
            <div class="text-gray-400 text-xs">
              Our most popular plan for small teams.
            </div>
          </div>
          <div class="flex mb-6">
            <span class="text-2xl font-bold">
              {{ branch.subscription.price.amount
              | currency : branch.subscription.price.currency }}
            </span>

            <span class="text-gray-400 text-xs font-medium pt-3">
              /
              {{
                 branch.subscription.price.period | translate
              }}
            </span>
          </div>
        </div>
        <div class="flex align-items-center">
          <div class="flex-1">
            <img *ngFor="
                let user of [].constructor(branch.subscription.userCount);
                let i = index
              " src="assets/img/user-avatar.avif" class="inline h-8 w-8 rounded-full border-2 border-white"
              [style.transform]="
                'translateX(calc(12px * ' +
                i +
                ' * ' +
                (translateService.currentLang === 'en' ? -1 : 1) +
                '))'
              " />
          </div>
          <div class="text-gray-400 text-xs font-medium">
            {{ branch.subscription.userCount }}/{{
            branch.subscription.maxUserCount
            }}
            {{ "translate_users" | translate }}
          </div>
        </div>
      </div>
      <div class="m-4 h-[1px] bg-gray-300">
        <div class="-translate-y-[1px] h-[3px] rounded-full" [style.width]="
            ((branch.subscription.price.periodInDays -
              branch.subscription.leftDays) /
              branch.subscription.price.periodInDays) *
              100 +
            '%'
          " [ngClass]="
            branch.subscription.leftDays > 0 ? 'bg-cyan-300' : 'bg-red-400'
          "></div>
      </div>
      <div class="flex pb-4 px-6">
        <div class="flex-1">
          <ng-container *ngIf="branch.subscription.leftDays > 0">
            <span class="text-gray-400 text-sm font-bold">
              {{ branch.subscription.leftDays }}
            </span>
            <span class="text-gray-400 text-xs">
              {{ "translate_days_to_restarted" | translate }}
            </span>
          </ng-container>
          <ng-container *ngIf="branch.subscription.leftDays === 0">
            <button (click)="showPayDialog()" type="button"
              class="bg-primary-700 hover:bg-primary-800 text-white text-sm rounded-full py-2 px-4">
              {{ "translate_renew" | translate }}
            </button>
          </ng-container>
        </div>
        <ng-container *ngIf="( !branch.hasInvoice || !branch.hasInvoice) ">
          <a *ngIf="!branch.hasInvoice" [routerLink]="'/plans/' + branch.branchId"
            class="text-primary-700 hover:text-primary-800 text-decoration-none">
            Upgrade Now
            <i class="fa-solid fa-arrow-up ms-1 ltr:rotate-45 rtl:-rotate-45"></i>
          </a>
        </ng-container>


      </div>
    </div>

    <div *ngIf="paymentMethod" class="rounded-2xl bg-white shadow p-4">
      <div class="font-semibold mb-1">
        {{ "translate_payment_method" | translate }}
      </div>
      <div class="text-gray-400 text-xs mb-8">
        {{ "translate_change_how_you_pay_for_your_plan" | translate }}
      </div>

      <div class="flex">
        <div>
          <img [src]="
              'assets/img/icons/payment-methods/' + paymentMethod.type + '.png'
            " height="48" width="48" />
        </div>
        <div class="flex-1 ms-3">
          <div class="text-gray-700 font-bold">
            {{ "translate_ending_with" | translate }}
            {{ paymentMethod.CardNumber.toString().slice(-4) }}
          </div>
          <div class="text-gray-400 text-sm">
            <div>
              {{ "translate_expiry" | translate }}
              {{ paymentMethod.ExpirationDate }}
            </div>
            <div class="flex mt-2">
              <div class="flex-1">
                <i class="fa-regular fa-envelope fa-fw"></i>
                {{ paymentMethod.email }}
              </div>
              <button type="button" (click)="showEditMethodDialog()"
                class="border border-gray-300 text-gray-700 text-sm hover:bg-gray-500 hover:text-white rounded-md py-1 px-3">
                {{ "translate_edit" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- جزء عرض الفواتير -->

  <div>
    <div class="flex mb-3">
      <div class="flex-1 mb-4">
        <div class="font-semibold">
          {{ "translate_invoices_title" | translate }}
        </div>
        <div class="text-xs text-gray-400">
          {{ "translate_invoices_subtitle" | translate }}
        </div>
      </div>
      <div>
        <button class="btn btn-outline-secondary borde-gray-300">
          <i class="fa-solid fa-cloud-arrow-down me-2"></i>
          {{ "translate_download_all" | translate }}
        </button>
      </div>
    </div>
    <div class="bg-white shadow-sm py-2">
      <div class="d-flex m-3">
        <div></div>
        <table class="w-full">
          <thead class="bg-teal-50 [&_th]:font-medium [&_th]:text-xs [&_th]:p-2">
            <tr class="[&_th]:text-gray-500">
              <th class="ps-3">
                {{ "translate_the_invoice" | translate }}
              </th>
              <th class="ps-3">expand</th>
              <th>
                {{ "translate_the_date" | translate }}
              </th>
         
              <th>
                {{ "translate_the_price" | translate }}
              </th>
              <th>
                {{ "translate_actions" | translate }}
              </th>
            </tr>
          </thead>
          <tbody
            class="[&_td]:font-semibold [&_td]:p-2 [&_td]:text-xs [&_td]:text-gray-600 [&_tr.invoice-header]:border-t [&_tr:first-child]:border-t-0 [&_tr]:border-gray-300">
            <ng-container *ngFor="let invoice of invoices">
              <tr class="invoice-header">
                <td>
                  <img src="assets/img/icons/files/pdf.svg" class="inline me-2" height="40" width="40" />
                  {{ invoice.code }}
                  <span class="ms-2 px-2 py-1   font-normal rounded-full" [ngClass]="
                      invoice.isPaid
                        ? '   text-green-700'
                        : '  text-red-400'
                    ">
                    <ng-container *ngIf="invoice.isPaid">
                      <i class="fa fa-check fa-fw"></i>
                      {{ "translate_paid" | translate }}
                    </ng-container>
                    <ng-container *ngIf="!invoice.isPaid">
                      <i class="fa fa-xmark fa-fw"></i>
                      {{ "translate_unpaid" | translate }}
                    </ng-container>
                  </span>
                </td>
                <td>
                  <i class="fa-solid text-2xl hover:text-gray-500 cursor-pointer" [ngClass]="{
                      'fa-circle-chevron-down': !expandedInvoices[invoice.code],
                      'fa-circle-chevron-up': expandedInvoices[invoice.code]
                    }" (click)="toggleInvoiceDetails(invoice.code)"></i>
                </td>
                <td>
                  {{ invoice.billingDate | date }}
                </td>


               

                <td>
                  {{ invoice.totalCost }}({{invoice.unPaidCost}})
                  {{ invoice.currency.code }}
                </td>
                <td>
                  <button *ngIf="!invoice.isPaid" type="button" (click)="
                      showPayment(
                        invoice,
                        invoice.billingItems[0],
                        invoice.billingItems[0].branchId,
                        true
                      )
                    " class="text-white bg-primary-500 hover:bg-primary-700 rounded-full px-3 py-2 font-normal">
                    {{ "translate_pay_now" | translate }}
                  </button>
                  <ng-container *ngIf="invoice.isPaid">
                    <button type="button" (click)="showInvoice(invoice.code)"
                      class="rounded-md hover:bg-primary-700 hover:text-white hover:border-primary-700 border-1 border-gray-300 p-2 text-gray-700 me-2">
                      <i class="fa-regular fa-eye"></i>
                    </button>
                    <button type="button"
                      class="rounded-md hover:bg-primary-700 hover:text-white hover:border-primary-700 border-1 border-gray-300 p-2 text-gray-700">
                      <i class="fa-solid fa-cloud-arrow-down"></i>
                    </button>
                  </ng-container>
                </td>
              </tr>
              <ng-container *ngIf="expandedInvoices[invoice.code]">
                <tr *ngFor="let item of invoice.billingItems">
                  <td>
                    <span>{{ item.title }}</span>
                  </td>
                  <td></td>
                  <td>
                    {{ item.billingDate | date }}
                  </td>
                 
                  <td class="opacity-80">
                    {{ item.cost }}
                    {{ item.currency.code }}
                  </td>
                  <td>

                    


                   <span  *ngIf="item.isPaid"
                      class="ms-2 px-2 py-1   font-normal rounded-full"
                      [ngClass]="
                      item.isPaid
                          ? 'text-green-700'
                          : 'text-red-400'
                      "
                    >
                      <ng-container *ngIf="item.isPaid">
                        <i class="fa fa-check fa-fw"></i>
                        {{ "translate_paid" | translate }}
                      </ng-container>
                   
                    </span>
      
               
                      

                    <button *ngIf="!item.isPaid" type="button"
                      (click)="showPayment(invoice, item, item.branchId, false)"
                      class="text-white bg-primary-500 hover:bg-primary-700 rounded-full px-3 py-2 font-normal">
                      {{ "translate_pay_now" | translate }}
                    </button>
                    <!-- <ng-container *ngIf="invoice.isPaid">
                      <button
                        type="button"
                        class="rounded-md hover:bg-primary-700 hover:text-white hover:border-primary-700 border-1 border-gray-300 p-2 text-gray-700 me-2"
                      >
                        <i class="fa-regular fa-eye"></i>
                      </button>
                    </ng-container>
                  </td> -->
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
        <!-- Payment Method Popup -->
        <div *ngIf="showPaymentPopup" class="popup">
          <div class="popup-content">
            <h2>{{'translate_select_payment_method'|translate }}</h2>
            <div class="payment-options">
              <button (click)="selectPaymentMethod('Mada')" [ngClass]="{ selected: selectedPaymentMethod === 'Mada' }">
                <img src="../../../assets/mada.png" alt="Mada" class="payment-logo" />
                Mada
              </button>
              <button (click)="selectPaymentMethod('Visa')" [ngClass]="{ selected: selectedPaymentMethod === 'Visa' }">
                <img src="../../../assets/visa.png" alt="Visa" class="payment-logo visa-logo" />
                Visa
              </button>
            </div>
            <div class="popup-actions">
              <button (click)="submitPaymentMethod()" class="submit-btn">
                Submit
              </button>
              <button (click)="closePaymentPopup()" class="cancel-btn">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- جزء المخفي لعرض تفاصيل الفاتورة -->
    <div class="hidden">
      <div #invioceDetails>
        <div *ngIf="selectedInvoice">
          <button
            class="m-2 text-gray-500 border border-gray-300 bg-gray-50 hover:bg-gray-500 hover:text-white p-2 rounded-md float-end">
            <i class="fa-solid fa-cloud-arrow-down me-2"></i>
            {{ "translate_download" | translate }}
          </button>
          <div class="clear-both pt-2 border-b-2 border-dashed border-gray-300">
            <div class="flex gap-3 p-8 pt-0">
              <div class="bg-gray-100 rounded-b-xl p-4 flex align-items-end">
                <img src="assets/img/logo/logo.svg" width="36" height="36" />
              </div>
              <div class="flex-1 rtl:text-right ltr:text-left">
                <div class="text-primary-400 font-bold text-3xl mt-8 mb-3">
                  {{ ("translate_invoice" | translate).toUpperCase() }}
                </div>
                <div class="grid grid-cols-2 gap-3 text-gray-400 text-sm">
                  <div>
                    <div class="mb-2">
                      {{ selectedInvoice.code }}
                    </div>
                    <div class="mb-2">
                      {{ "translate_invoice_date" | translate }}:
                      {{ selectedInvoice.billingDate | date }}
                    </div>
                  </div>
                  <div>
                    <div class="mb-2">
                      {{ "translate_company_id" | translate }}:
                      {{ selectedInvoice.companyId }}
                    </div>
                    <div>
                      {{ "translate_status" | translate }}:
                      {{
                      "translate_" +
                      (selectedInvoice.isPaid ? "paid" : "unpaid")
                      | translate
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mx-8 mt-3 text-gray-800 rtl:text-right ltr:text-left text-xs">
            <table class="w-full [&_td]:px-2">
              <thead class="bg-teal-50 [&_td]:py-3">
                <tr class="text-nowrap">
                  <td>
                    {{ "translate_item" | translate }}
                  </td>
                  <td>
                    {{ "translate_cost" | translate }}
                  </td>
                 
                  <td>
                    {{ "translate_currency" | translate }}
                  </td>
                  <td class="ltr:text-right rtl:text-left">
                    {{ "translate_total_price" | translate }}
                  </td>
                </tr>
              </thead>
              <tbody class="[&_td]:py-2">
                <tr *ngFor="let item of selectedInvoice.billingItems" class="border-b-2 border-gray-200">
                  <td class="font-semibold">{{ item.title }}</td>
                  <td>
                    {{ item.cost | number : "1.1" }}
                  </td>
                
                  <td>
                    {{ item.currency.code }}
                  </td>
                  <td class="ltr:text-right rtl:text-left">
                    {{ item.cost | number : "1.1" }}
                  </td>
                </tr>
              </tbody>
              <tbody class="text-sm font-medium [&_td]:py-3">
                <tr>
                  <td colspan="3"></td>
                  <td class="text-gray-500">
                    {{ "translate_subtotal" | translate }}
                  </td>
                  <td class="ltr:text-right rtl:text-left">
                    {{ selectedInvoice.totalCost | number : "1.1" }}
                    {{ selectedInvoice.currency.code }}
                  </td>
                </tr>
                <tr>
                  <td colspan="3"></td>
                  <td class="text-gray-500 border-b-2">
                    {{ "translate_tax" | translate }}
                  </td>
                  <td class="ltr:text-right rtl:text-left border-b-2">
                    0
                    <!-- Assuming no tax for simplicity -->
                    {{ selectedInvoice.currency.code }}
                  </td>
                </tr>
                <tr>
                  <td colspan="3"></td>
                  <td class="text-gray-500 border-b-2">
                    {{ "translate_total" | translate }}
                  </td>
                  <td class="ltr:text-right rtl:text-left border-b-2">
                    {{ selectedInvoice.totalCost | number : "1.1" }}
                    {{ selectedInvoice.currency.code }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="mx-8 mt-16 ltr:float-right rtl:float-left">
            <span class="text-xs text-gray-950 me-2">
              {{ "translate_power_by" | translate }}:
            </span>
            <img src="assets/img/logo-text.svg" class="inline-block h-5" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>