import { Component, OnDestroy } from '@angular/core';
import { PaymentMethod, PaymentMethodDTO } from '@s-app/common/models';
import { SideDialogService } from '@s-app/common/services';
import { PaymentCardCacheService } from '@s-app/core/services/cache';
import { Subject, takeUntil } from 'rxjs';

@Component({
  templateUrl: './payments.component.html',
})
export class PaymentsComponent implements OnDestroy {
  public paymentMethods: PaymentMethodDTO[];

  private destroy$: Subject<void> = new Subject<void>();

  public constructor(
    private sideDialogService: SideDialogService,
    paymentCardCacheService: PaymentCardCacheService
  ) {
    paymentCardCacheService.list$
      .pipe(takeUntil(this.destroy$))
      .subscribe((cards: PaymentMethodDTO[]) => {
        this.paymentMethods = cards;
      });
  }

  public showPayDialog(): void {
    this.sideDialogService.showPayDialog();
  }

  public showEditMethodDialog(p: PaymentMethodDTO): void {

    var paymentMethodModel: PaymentMethod = {
      CardNumber: Number(p.lastFourDigits),
      HolderName: p.holderName,
      ExpireMonth: p.expiryMonth,
      ExpireYear:    Number(`${p.expiryYear.toString().slice(  -2 )}`) ,
      //   ExpirationDate : `${p}/${p.expiryYear.toString().slice(  -2 )}`,
      Id: p.id,
      CVV: 0,
      type: p.type,
      email: p.email ?? "",
      IsDefault: p.isDefault,

    }
    console.log(`${p.expiryYear.toString().slice(  -2 )}`);
    console.log(paymentMethodModel);
    this.sideDialogService.showEditMethodDialog(paymentMethodModel);
  }

  public showNewMethodDialog(): void {
    this.sideDialogService.showNewMethodDialog();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.destroy$.unsubscribe();
  }
}
