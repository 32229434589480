import { ElementRef, Injectable } from '@angular/core';
import { Observable, distinctUntilChanged, mergeMap } from 'rxjs';

interface Option {
  root?: Element | null;
  rootMargin?: string;
  threshold?: number | number[];
}

@Injectable({
  providedIn: 'root',
})
export class IntersectionObserverService {
  public createAndObserve(
    element: ElementRef,
    option: Option = {}
  ): Observable<boolean | any> {
    return new Observable((observer) => {
      const intersectionObserver = new IntersectionObserver((entries) => {
        observer.next(entries);
      }, option);

      intersectionObserver.observe(element.nativeElement);

      return () => {
        intersectionObserver.disconnect();
      };
    }).pipe(
      mergeMap((entries: IntersectionObserverEntry[] | any) => entries),
      // map((entry) => entry.isIntersecting),
      distinctUntilChanged()
    );
  }
}
