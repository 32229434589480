import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { BillingService } from '@bl-src/app/core/services/billing.service';

@Component({
  selector: 'app-payment-failure',
  standalone: true,
  imports: [],
  templateUrl: './payment-failure.component.html',
  styleUrls: ['./payment-failure.component.scss'],
})
export class PaymentFailureComponent implements OnInit {
  id: string | null = null;
  test: string = 'test';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private billingService: BillingService
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.id = params.get('id');
      if (this.id) {
        if (this.id) {
          this.billingService.sendFailedPaymentReference(this.id).subscribe(
            (response) => {
              console.log('Response from API:', response);
            },
            (error) => {
              console.error('Error from API:', error);
            }
          );
        }
      }
    });

    // this.route.url.subscribe(urlSegments => {
    //   const fullPath = urlSegments.join('/');
    //   const idStartIndex = fullPath.indexOf('payment-failure/') + 'payment-failure/'.length;
    //   const idEndIndex = fullPath.indexOf('?');
    //   this.id = fullPath.substring(idStartIndex, idEndIndex);
    //   console.log(this.id);

    //   if (this.id) {
    //     this.billingService.sendFailedPaymentReference(this.id).subscribe(response => {
    //       console.log('Response from API:', response);
    //     }, error => {
    //       console.error('Error from API:', error);
    //     });
    //   }
    // });
  }

  goToInvoicesPage() {
    this.router.navigate(['/invoices']);
  }
}
