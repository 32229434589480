<div class="grid gap-4 md:grid-cols-2">
  <div *ngFor="let method of paymentMethods" class="rounded-2xl bg-white shadow p-4">
    <div class="flex">
      <div>
        <div *ngIf="method.type === undefined" class="border-[1px] border-gray-300 bg-white rounded flex align-items-center w-[48px] h-[48px]">
          <i class="fa-regular fa-credit-card fa-fx fa-2x block m-auto text-gray-600"></i>
        </div>
        <img *ngIf="method.type !== undefined" [src]="'assets/img/icons/payment-methods/' + method.type + '.png'" height="48" width="48" />
      </div>
      <div class="flex-1 ms-3">
        <div class="text-gray-700 font-bold">
          {{ 'translate_ending_with' | translate }}
          {{ method.CardNumber | ending:3 }}
        </div>
        <div class="text-gray-400 text-sm">
          <div>
            {{ 'translate_expiry' | translate }}
            {{ method.ExpirationDate }}
          </div>
          <!-- <div class="mt-2">
            <i class="fa-regular fa-envelope fa-fw"></i>
            {{ method.email }}
          </div> -->
        </div>
      </div>
    </div>
    <div class="flex mt-8">
      <!-- <div class="flex-1">
        <div class="text-xs text-gray-400">
          {{ 'translate_assigned_in' | translate }}
        </div>
        <div class="text-sm text-gray-700 font-semibold">
          {{ method.assignedIn?.length }}
          {{ 'translate_branches' | translate }}
        </div>
      </div> -->
      <div class="flex align-items-end">
        <button (click)="showEditMethodDialog(method)" type="button"
          class="border border-gray-300 text-gray-700 text-sm hover:bg-gray-500 hover:text-white rounded-md py-1 px-3">
          {{ 'translate_edit' | translate }}
        </button>
      </div>
    </div>
  </div>
  <div (click)="showNewMethodDialog()"
    class="flex align-items-center rounded-2xl bg-white border-dashed border-2 border-sky-500 text-sky-500 hover:border-sky-600 hover:text-sky-600 cursor-pointer">
    <div class="text-center flex-1 text-sm">
      <i class="fa-solid fa-plus me-2 py-20"></i>
      {{ 'translate_add_new_payment' | translate }}
    </div>
  </div>
</div>





