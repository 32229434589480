<div class="px-2 mb-20 mx-auto max-w-[35rem] text-center ltr">
  <div class="mb-6 text-3xl font-bold text-gray-800">
    Simple pricing for everyone
  </div>
  <div class="text-gray-400">
    Explore our pricing options tailored to suit your needs. From basic plans to
    enterprise solutions, find the perfect fit for your budget and requirements.
  </div>
</div>
<div class="bg-white shadow rounded-b-2xl p-4 mb-24 relative ltr">
  <div class="grid grid-cols-4">
    <div class="flex align-items-end border border-gray-300 rounded-2xl mt-10">
      <div class="flex-1 p-4">
        <div class="text-gray-400 mb-3">Pick your plan:</div>
        <div class="text-gray-800 font-medium -mb-3">
          <form>
            <label
              *ngFor="let period of periodsApi"
              class="flex align-items-start mb-2 text-sm font-semibold cursor-pointer [&:has(input:checked)_.checked-icon]:inline [&:has(input:checked)_.unchecked-icon]:hidden"
            >
              <input
                type="radio"
                (click)="onPeriodChange(period.code, period.billingName)"
                [checked]="selectedPayPeriodApi === period.code"
                class="hidden"
                [value]="period.code"
                name="pay-period"
              />
              <div class="me-1">
                <i
                  class="unchecked-icon fa-regular fa-circle fa-lg text-teal-500"
                ></i>
                <i
                  class="checked-icon fa-solid hidden fa-check-circle fa-lg text-teal-500"
                ></i>
              </div>
              <span class="flex-1">
                {{ period.billingName }}
                <span
                  *ngIf="period.code === 'cycle360'"
                  class="text-xs inline-block text-primary-500"
                  >Save 20%</span
                >
              </span>
            </label>
          </form>
        </div>
      </div>
    </div>
    <div  *ngFor="let plan of planApi; let i = index" [class.mt-10]="!plan.isFeatured" >
      <div class="flex align-items-end p-4 rounded-2xl text-center h-full"  [ngClass]="{ 'bg-primary-700 text-gray-100': plan.isFeatured }"  >
        <div class="flex-1">
          <div *ngIf="plan.isFeatured">
            <div class="text-gray-400 text-xs mb-8">Most selected</div>
          </div>
          <div class="font-bold mb-8">{{ plan.name || "Plan Name" }}</div>
          <div class="text-2xl font-bold mb-2">
            {{ plan[selectedPayPeriodApi]?.price | currency: plan[selectedPayPeriodApi]?.currency }} 
          </div>
          <div class="text-xs text-gray-400 mb-3">
            Per {{ getPeriodName(selectedPayPeriodApi) }}
          </div>
          <!-- <a *ngIf="!currentBranchId" 
            (click)="openPopup(plan[selectedPayPeriodApi].planPriceId)"
            class="block cursor-pointer text-primary-500 w-full bg-gray-50 hover:bg-primary-800 border-1 hover:border-2 hover:border-gray-50 hover:text-gray-50 border-primary-500 rounded-md p-2"
          >
            Get Started
          </a> -->
          <a *ngIf="currentBranchId"  (click)="openPopup(plan[selectedPayPeriodApi].planPriceId)" 
          class="block cursor-pointer text-primary-500 w-full bg-gray-50 hover:bg-primary-800 border-1 hover:border-2 hover:border-gray-50 hover:text-gray-50 border-primary-500 rounded-md p-2">
             {{'translate_subscribe_now' | translate}}
          </a>
        </div>
      </div>
    </div>


  </div>
  <div *ngIf="showPopup" class="popup-overlay">

    <div *ngIf="nonSubscribedBranches.result.length==0" class="popup-content">
      <button class="close-btn" (click)="closePopup()">×</button>
      <p>
        {{'translate_non_branch_exsit'|translate}}
      </p>
    </div>
    <div *ngIf="nonSubscribedBranches.result.length>0" class="popup-content">
      <h2>Select a Branch</h2>
      <button class="close-btn" (click)="closePopup()">×</button>
      <div class="branches-grid">
        <div
          *ngFor="let branch of nonSubscribedBranches.result"
          class="branch-item"
          [class.selected]="branch.branchId === selectedBranchId"
          (click)="selectBranch(branch.branchId)"
        >
          <input
            type="radio"
            [id]="branch.branchId"
            name="branch"
            [value]="branch.branchId"
            [(ngModel)]="selectedBranchId"
          />
          <label [for]="branch.branchId">
            <i class="fas fa-code-branch"></i>
            {{ branch.branchName }}
          </label>
        </div>
      </div>
      <button class="submit-btn" (click)="submitBranch()">Submit</button>
    </div>
  </div>
  <div
    #tracking
    class="absolute bottom-72"
    style="top: calc(100vh + 62px)"
  ></div>
  <div class="mt-8">
    <div
      *ngFor="let featureName of featuresApi; let i = index"
      class="grid grid-cols-4 rounded-2xl my-2"
      [ngClass]="{ 'bg-teal-100/50': i % 2 === 0 }"
    >
      <div class="py-6 px-3 font-medium text-sm">
        {{ featureName }}
      </div>
      <div
        *ngFor="let plan of planApi"
        class="p-6 text-center flex align-items-center"
      >
        <div class="flex-1 text-center text-xl">
          <i
            *ngIf="isFeatureExist(plan.features, featureName)"
            class="fa-solid fa-circle-check text-teal-400"
          ></i>
          <i
            *ngIf="!isFeatureExist(plan.features, featureName)"
            class="fa-solid fa-circle-xmark text-red-400"
          ></i>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="text-center max-w-[35rem] mx-auto mb-12">
  <div class="text-3xl font-bold text-gray-800 mb-6">
    Frequently asked questions
  </div>
  <div class="text-gray-400">
    If you are hesitating, do not worry - we are here to explain everything you
    might want to know. Let us help!
  </div>
</div>
<details
  *ngFor="let question of questions"
  class="[&[open]_.plus]:hidden [&:not(&[open])_.minus]:hidden border-b [&:last-of-type]:border-b-0"
>
  <summary class="flex align-items-center py-6 px-8">
    <div class="flex-1 text-gray-800 text-lg font-bold">
      {{ question.question }}
    </div>
    <span
      class="inline-block h-5 w-5 rounded-full border-2 border-gray-400 relative"
    >
      <span
        class="text-gray-400 absolute top-1/2 left-1/2 -translate-y-1/2 text-xs -translate-x-1/2"
      >
        <i class="fa-solid fa-plus plus"></i>
        <i class="fa-solid fa-minus minus"></i>
      </span>
    </span>
  </summary>
  <div class="text-gray-700 px-8 pb-6">
    {{ question.answer }}
  </div>
</details>
<div class="my-20 p-16 bg-teal-500/75 rounded-2xl">
  <div class="flex text-white">
    <div class="flex-1 text-2xl">
      <div>Have a specific question?</div>
      <div class="font-bold">Check out Our Support Center</div>
    </div>
    <div class="flex align-items-center">
      <button
        type="button"
        class="bg-white/95 hover:bg-primary-600 hover:text-white text-gray-800 text-sm rounded-md py-3 px-8"
      >
        Ask a Question
      </button>
    </div>
  </div>
</div>


<div *ngIf="showPaymentPopup" class="paypopup-overlay">
  <div class="paypopup-content">
    <h2>Select Payment Method</h2>
    <div class="paypopup-options">
      <button
        (click)="selectPaymentMethod('Mada')"
        [ngClass]="{ 'pay-selected': selectedPaymentMethod === 'Mada' }"
      >
        <img
          src="../../../assets/mada.png"
          alt="Mada"
          class="paypopup-logo"
        />
        Mada
      </button>
      <button
        (click)="selectPaymentMethod('MasterCard')"
        [ngClass]="{ 'pay-selected': selectedPaymentMethod === 'MasterCard' }"
      >
        <img
          src="../../../assets/visa.png"
          alt="MasterCard"
          class="paypopup-logo"
        />
        MasterCard
      </button>
    </div>
    <div class="paypopup-actions">
      <button (click)="submitPaymentMethod()" class="pay-submit-btn">
        Submit
      </button>
      <button (click)="closePaymentPopup()" class="pay-cancel-btn">
        Cancel
      </button>
    </div>
  </div>
</div>
