<div class="relative min-h-full   flex flex-row flex-wrap justify-around	">




  <div class="plan-container">


    <div class="mb-6 p-4   pt-10 rounded-lg">
      <div class="mb-10 modal-title">

        <span class="font-bold text-neutral-600 text-xl ">
          {{ 'translate_glamera_subscription_plans_text' | translate }}

        </span>
        <p class="text-xs  text-gray-400 my-1">
         {{'translate_overall_description' |translate}}
        </p>
      </div>
      <div class="text-sm mb-6">
        <span>
          {{ 'translate_subscription_plans_text' | translate }}
          <i class="fa-regular fa-circle-question"></i>
        </span>

        <p class="text-xs  text-gray-400 my-1">
         
          {{'translate_discount_explanation' |translate}}
        </p>

      </div>
      <form [formGroup]="formGroup" class="text-xs" (ngSubmit)="CheckSubscriptionChange()">
        <div class="relative">
          <app-form-grid-spinner [isShown]="isSubmitting"></app-form-grid-spinner>
          <div class=" ">
            <div class="mb-2 pb-3 relative">
              <label class="block mb-2">
                {{ 'translate_choose_plan' | translate }}
              </label>

              <div class="mb-8 rtl">


                <select (change)="onPlanChosen($event)" formControlName="planId"
                  class="block w-full border-gray-300 hover:border-primary-700 rounded-md py-2 px-3 text-xs ">
                  <option *ngFor="let plan of plans;trackBy: trackByPlanId" [value]="plan.planId">{{plan.name}}</option>

                </select>
              </div>








              <div class="flex  mb-8">

                <div class="cycle" [class.selected]="formGroup.get('cycle')?.value == 30">
                  <div class=" flex mt-1 p-2 pb-2 rounded-r rounded-l items-center "
                    style="border-bottom: solid lightgray 0.5px;">
                    <input type="radio" id="month" name="cycle" value="30" class="mx-2" formControlName="cycle"
                      [checked]="formGroup.get('cycle')?.value === 30">
                    <label for="month" class="w-full">
                      {{ 'translate_monthly' | translate }}
                    </label>
                  </div>


                  <div>
                    <div class="  p-2 pb-2">
                      <label for="month" class=" price text-sm text-slate-900 text-gray-800 w-full">
                        {{(selectedPlan?.cycle30.price ?? 0) | currency:selectedPlan?.cycle30.currency ?? 'USD'}}
                      </label>

                      <p for="month" class="price-label text-gray-400  w-full">
                       
                          <span class="text-xs">
                            {{ 'translate_billed' | translate }} 
                            {{ 'translate_monthly' | translate }}
                          </span>
                   
                      </p>
                    </div>
                  </div>
                </div>

                <div class="cycle" [class.selected]="formGroup.get('cycle')?.value == 90">
                  <div class=" flex mt-1 p-2 pb-2 rounded-r rounded-l items-center "
                    style="border-bottom: solid lightgray 0.5px;">
                    <input type="radio" id="quarter" name="cycle" value="90" class="mx-2" formControlName="cycle"
                      [checked]="formGroup.get('cycle')?.value == 90">
                    <label for="quarter" class="w-full">
                      {{ 'translate_quarterly' | translate }}
                    </label>
                  </div>


                  <div>
                    <div class="  p-2 pb-2">
                      <label for="quarter" class=" price text-sm text-slate-900 text-gray-700 w-full">
                        {{((selectedPlan?.cycle90.price ?? 0) / 3 ) | currency:selectedPlan?.cycle90.currency ?? 'USD'}}
                      </label>

                      
                      <p for="quarter" class="price-label text-gray-400 w-full ">
                       <span class="text-xs  text-gray-600">
                        {{(selectedPlan?.cycle90.price ?? 0) | currency:selectedPlan?.cycle90.currency ?? 'USD'}} 
                       </span>
                        <span class="text-xs">
                          {{ 'translate_billed' | translate }}  
                          {{ 'translate_quarterly' | translate }}
                        </span>
                       
                      </p>
                    </div>
                  </div>
                </div>

                <div class="cycle" [class.selected]="formGroup.get('cycle')?.value == 360">
                  <div class=" flex mt-1 p-2 pb-2 rounded-r rounded-l items-center "
                    style="border-bottom: solid lightgray 0.5px;">
                    <input type="radio" id="year" name="cycle" value="360" class="mx-2" formControlName="cycle"
                      [checked]="formGroup.get('cycle')?.value == 360">
                    <label for="year" class="w-full">
                      {{ 'translate_yearly' | translate }}
                    </label>
                  </div>


                  <div>
                    <div class="  p-2 pb-2">
                      <label for="year" class=" price text-sm text-slate-900 text-gray-700 w-full">
                        {{(selectedPlan?.cycle360.price ??0 )/ 12 | currency:selectedPlan?.cycle360.currency ?? 'USD'}}
                      </label>

                      <p for="year" class="price-label text-gray-400 w-full ">

                        <span class="text-xs  text-gray-600">
                          {{(selectedPlan?.cycle360.price ??0 ) | currency:selectedPlan?.cycle360.currency ?? 'USD'}}
                         </span>
                          <span class="text-xs">
                            {{ 'translate_billed' | translate }} 
                            {{ 'translate_yearly' | translate }}
                          </span>
 
                      </p>
                    </div>
                  </div>
                </div>


              </div>

              <div class="text-sm mb-6">
                <span>
                  {{ 'translate_overview_text' | translate }}
                  <!-- <i class="fa-regular fa-circle-question"></i> -->
                </span>

                <p class="text-xs  text-gray-400 my-1">
                   {{'translate_overview' |translate}}
                </p>

              </div>

            </div>

          </div>



          <div class="  border-gray-300 py-6 px-10 text-end absolute bottom-0 -left-10 -right-10 -mb-10">
            <button (click)="hideDialog()" type="button"
              class="py-2 px-3 min-w-28 border-1 text-gray-500 hover:text-primary-800 border-primary-300 hover:border-primary-700 rounded-md me-2">
              {{ 'translate_cancel' | translate }}
            </button>
            <button type="submit"   [disabled]="isSubmitting&&formGroup.invalid"
              class="py-2 px-3 min-w-28 border-1 text-white bg-primary-700 hover:bg-primary-800 border-primary-300 rounded-md">
              {{ 'translate_subscribe_now' | translate }}
            </button>
          </div>

        </div>
      </form>
    </div>
  </div>

  <div class="plan-container-content">
    <div class="mb-6 p-4 bg-gray-100 pt-10 rounded-lg">
      <div class="mb-6">
        <img src="assets/img/logo-text.svg" class="h-8" alt="">
      </div>
      <p class="text-sm text-gray-600 my-8">
        {{'translate_encouragement_read_features' |translate}}
      </p>

      <div class="flex items-center justify-between">
        <h2 class="text-gray-900	font-bold	text-lg">{{selectedPlan?.name??'Subscription Plan' }}</h2>

      </div>



      <div class="features">
        <ul>
          <ng-container *ngFor="let feature of selectedPlan?.features">

            <li [ngClass]="{'nofeatue':feature.isExist==false }" *ngIf="feature.isExist">
              <i style="color: #694ed6;" class="fa-solid fa-check"></i>
              {{feature.name}}
              <i [title]="feature.description" class="fa-regular fa-circle-question" aria-></i>
            </li>
          </ng-container>

        </ul>

        <ul>
          <ng-container *ngFor="let feature of selectedPlan?.features">

            <li [ngClass]="{'nofeatue':feature.isExist==false }" *ngIf="!feature.isExist">

              <i style="color: red;" class="fa-solid fa-ban"></i>

              {{feature.name}}
              <i [title]="feature.description" class="fa-regular fa-circle-question" aria-></i>
            </li>
          </ng-container>

        </ul>


      </div>


      <div class="mb-2 sum-price flex items-center justify-between">
        <h2 class="text-gray-900	font-bold	text-lg">{{'translate_summary'|translate}}</h2>
        <p class="text-xs text-slate-300	">{{date }}</p>
      </div>

      <div class="mb-2 sum-price flex items-center justify-between">
        
        <p class="text-xs text-slate-500	">{{ cycleText|translate}}</p>
        <p class="text-xs text-slate-700 font-bold	">
          {{selectedCyclePrice?.price ?? 0.00 | currency:selectedCyclePrice?.currency ?? 'USD'}}
        </p>
      </div>

      <div class=" mb-2 sum-tax flex items-center justify-between">
        <p class="text-xs text-slate-500	">{{'translate_est_tax' |translate }}</p>
        <p class="text-xs text-slate-700 font-bold	">$0.00</p>
      </div>

      <div class="mb-2 sum-total flex items-center justify-between">
        <p class="text-xs text-slate-500	">{{'translate_total_inc_tax'|translate}}</p>
        <p class="text-xs text-slate-700 font-bold	"> {{selectedCyclePrice?.price ?? 0.00 |
          currency:selectedCyclePrice?.currency ?? 'USD'}}</p>
      </div>


    </div>
  </div>


</div>




<div *ngIf="showPaymentPopup" class="paypopup-overlay">
  <div class="paypopup-content">
  <!-- Render templates based on the value of change.isUpgrade -->
  <ng-container *ngIf="change.isUpgrade === 0">
    <ng-container *ngTemplateOutlet="newSubscriptionTemplate"></ng-container>
  </ng-container>

  <ng-container *ngIf="change.isUpgrade === 1">
    <ng-container *ngTemplateOutlet="downgradeTemplate"></ng-container>
  </ng-container>

  <ng-container *ngIf="change.isUpgrade === 2">
    <ng-container *ngTemplateOutlet="upgradeTemplate"></ng-container>
  </ng-container>




  <div class="paypopup-actions">
    <button (click)="subscribe()" class="pay-submit-btn">
      {{'translate_submit'|translate}}
    </button>
    <button (click)="closePaymentPopup()" class="pay-cancel-btn">
      {{'translate_cancel'|translate}}
    </button>
  </div>
</div>
</div>


<ng-template #downgradeTemplate>
  
    <h2 class="popup-title">{{'translate_downgrade_your_subscription' |translate}}</h2>
    <div class="paypopup-options">
      <p class="text-warning">{{'translate_downgrade_warning'|translate}}</p>
    </div>
 
</ng-template>

<ng-template #upgradeTemplate>
 
    <h2 class="popup-title">{{'translate_upgrade_your_subscription' |translate}}</h2>
    <div class="paypopup-options">
      <p class="text-warning">   
        {{'translate_upgrade_warning'|translate}}
       <span class="font-bold  mc"> {{change.payment.cost | currency:change.payment.currency}}</span>
      </p>
  
    </div>
    <ng-container *ngTemplateOutlet="paymentTemplate"></ng-container>
  
</ng-template>


<ng-template #newSubscriptionTemplate>
 
    <h2 class="popup-title" >{{'translate_new_subscription'|translate}}</h2>
    <div class="mt-3 mb-3">
      <p class="text-warning">{{'translate_new_subscription_warning'|translate}}</p>
      

      <p class="text-confirm"> {{'translate_do_proceed'|translate}}</p>
    </div>
    <ng-container *ngTemplateOutlet="paymentTemplate"></ng-container>
 
</ng-template>



<ng-template #paymentTemplate>
 
    <h4 class="font-bold">{{'translate_select_payment_method' |translate}}</h4>
    <div class="paypopup-options">
      <button (click)="selectPaymentMethod(2)" [ngClass]="{ 'pay-selected': selectedPaymentMethod === 2 }">
        <img src="../../../assets/mada.png" alt="Mada" class="paypopup-logo" />
        Mada
      </button>
      <button (click)="selectPaymentMethod(0)" [ngClass]="{ 'pay-selected': selectedPaymentMethod === 0 }">
        <img src="../../../assets/visa.png" alt="MasterCard" class="paypopup-logo" />
        MasterCard
      </button>
    </div>
 
</ng-template>