declare let process: any;
const env = process.env.NODE_ENV;

export const environment = {
  saasURL: 'https://dev.glamera.com',
  apiURL: 'https://api-2024.glamera.com/api/v5/b2c/rl',
  apiUrl: 'https://api-dev-new.glamera.com/api',
  RootDomain: "glamera.com",
};

