import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  CountryISO,
  PhoneNumberFormat,
  SearchCountryField,
} from 'ngx-intl-tel-input';

@Component({
  templateUrl: './contact-us.component.html',
})
export class ContactUsComponent {
  public contactForm: FormGroup = new FormGroup({
    name: new FormControl(null, Validators.required),
    email: new FormControl(null, [Validators.required, Validators.email]),
    phone: new FormControl(null, Validators.required),
    message: new FormControl(null, [
      Validators.required,
      Validators.maxLength(250),
    ]),
  });

  public preferredCountries: CountryISO[] = [
    CountryISO.SaudiArabia,
    CountryISO.Egypt,
  ];

  public selectedCountryISO: CountryISO = CountryISO.SaudiArabia;

  public searchCountryField: SearchCountryField[] = [
    SearchCountryField.Iso2,
    SearchCountryField.Name,
  ];

  public numberFormat: PhoneNumberFormat = PhoneNumberFormat.International;
}
