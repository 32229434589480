declare let process: any;
const env = process.env.NODE_ENV;

export const environment = {
  saasURL: 'http://localhost:64731',
  apiURL: 'https://localhost:5001/api/v5/b2c/rl',
  apiUrl: 'https://localhost:5001/api',
  RootDomain: "glamera.com",
};

