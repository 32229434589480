import { CommonModule } from '@angular/common';
import { Component, ComponentRef, HostListener, input, Input, OnInit, Output, Renderer2, ViewChild, ViewContainerRef } from '@angular/core';
import { SideDialogService } from '@s-app/common/services';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-dialog',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'] 
})
export class  DialogComponent implements OnInit {

  constructor(private dialogService : SideDialogService , private renderer: Renderer2,) {



   }


   @HostListener('window:keydown', ['$event'])
   public presKey(event: KeyboardEvent): void {
     if (event.code === 'Escape') {
        this.hideSideDialog();
     }
   }

   @ViewChild('dialog', {
    static: true,
    read: ViewContainerRef,
  })
  public dialog!: ViewContainerRef;

  isShowenDialog    : boolean = false;
  private dialogComponent: ComponentRef<any>;

   ngOnInit(): void {
    this.dialogService.sideDialogComponent.subscribe((response: any) => {
      if (response && response.component) {
        this.showSideDialogDialog(response.component, response.data);
      }
    });
    this.dialogService.showDialogSubject.subscribe((isShowDialog: boolean) => {
      if (!isShowDialog) {
        this.hideSideDialog();
      }
    });


  }

   public showSideDialogDialog(
    component: any,
    data: { [key: string]: any }
  ): void {
    this.hideSideDialog();
    this.showSideDialog();
    this.dialogComponent = this.dialog.createComponent(component);
   
   this.side = data['side'] ?? 'right';
   this.width= data['width'] ?? '80%';
    Object.keys(data).forEach((key) => {
      this.dialogComponent.instance[key] = data[key];
    });
    this.dialogComponent.changeDetectorRef.detectChanges();
  }

  public hideSideDialog(): void {
    this.renderer.removeClass(document.body, 'overflow-hidden');
    this.isShowenDialog = false;
    if (this.dialogComponent) {
      this.dialogComponent.destroy();
      this.closeDialog() ;
    }
  }

  private showSideDialog(): void {
    this.renderer.addClass(document.body, 'overflow-hidden');
    this.isShowenDialog = true;
  }

 
  @Input('side') side :'center'|'right'|'left' ='right'; 
  @Input('width') width : string = '50%';

  @Input()
  public heading: string = '';

  @Input()
  public visible: boolean = false;

  @Input()
  public showCloseButton: boolean = true;

  @Input()
  public showFooter: boolean = true;

  @Input()
  public showConfirmButton: boolean = true;

  @Input()
  public disableConfirmButton: boolean = false;

  @Input()
  public confirmButtonText: string = 'Confirm';

  @Input()
  public confirmButtonClass: string;

  @Input()
  public showCancelButton: boolean = true;
  
  @Output()
  public onConfirm: Subject<void> = new Subject();
  
  @Input()
  public cancelButtonText: string = 'Cancel';
  
  @Input()
  public cancelButtonClass: string;

  @Output()
  public onClose: Subject<void> = new Subject();
  
  @Output()
  public onCancel: Subject<void>;

  @HostListener('document:keyup', ['$event'])
  public onKeyup(event: KeyboardEvent) {
    if (event.code === 'Escape') {
      this.closeDialog();
    }
  }

 

  public closeDialog(): void {
    this.visible = false;
    this.isShowenDialog = false;
    this.onClose.next()
  }
}
