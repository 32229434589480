 

<div class="fixed z-10 h-full  top-0  w-10/12  overflow-auto	  " 
    [ngClass]="{hidden: !isShowenDialog ,
    'ltr:right-0 rtl:left-0':side == 'right' ,
    'ltr:left-0 rtl:right-0':side == 'left' , 
    'w-full' : side== 'center',
    'max-w-[35rem]':side!= 'center' ,
     }">

  <div class="flex container" 
  [style.width]="side === 'center' ? width : 'auto'"
  [ngClass]="{ 'm-8 p-8 m-auto'  : side== 'center'}">


    <div *ngIf="side!='center'" (click)="hideSideDialog()">
      <button type="button" class="rounded-full h-10 w-10 bg-gray-50 hover:bg-white m-4 hover:text-red-400">
        <i class="fa-solid fa-xmark fa-lg"></i>
      </button>
    </div>

    <div class="relative flex-1 bg-white shadow  "
      [ngClass]="{'p-10 overflow-y-auto h-[100vh] ':side!='center'}" 
      style="border-radius: 25px;">


      <div *ngIf="side=='center'" (click)="hideSideDialog()" 
      class=" absolute top-5 z-20 ltr:right-5 rtl:left-5"  >


        <button type="button" class="rounded-full h-10 w-10 bg-gray-50 hover:bg-white m-4 hover:text-red-400">
          <i class="fa-solid fa-xmark fa-lg"></i>
        </button>


      </div>
    
      <div class=""> 
        <ng-container #dialog></ng-container>
      </div>

    </div>

  </div>
</div>