import { Component, OnDestroy } from '@angular/core';
import { PaymentMethod } from '@s-app/common/models';
import { SideDialogService } from '@s-app/common/services';
import { PaymentCardCacheService } from '@s-app/core/services/cache';
import { Subject, takeUntil } from 'rxjs';

@Component({
  templateUrl: './payments.component.html',
})
export class PaymentsComponent implements OnDestroy {
  public paymentMethods: PaymentMethod[];

  private destroy$: Subject<void> = new Subject<void>();

  public constructor(
    private sideDialogService: SideDialogService,
    paymentCardCacheService: PaymentCardCacheService
  ) {
    paymentCardCacheService.list$
      .pipe(takeUntil(this.destroy$))
      .subscribe((cards: PaymentMethod[]) => {
        this.paymentMethods = cards;
      });
  }

  public showPayDialog(): void {
    this.sideDialogService.showPayDialog();
  }

  public showEditMethodDialog(paymentMethod: PaymentMethod): void {
    this.sideDialogService.showEditMethodDialog(paymentMethod);
  }

  public showNewMethodDialog(): void {
    this.sideDialogService.showNewMethodDialog();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.destroy$.unsubscribe();
  }
}
