import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GlameraSharedService } from 'glamera-shared';
import { environment } from 'environments/environment.local';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private sharedService: GlameraSharedService) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,

  ): Observable<HttpEvent<any>> {
    const token =   this.sharedService.token;
    if(!token){
      debugger;
      window.location.href = environment.saasURL + '/login';
    }
    const modifiedRequest = request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });

    return next.handle(modifiedRequest);
  }
}
