import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";


@Injectable({
    providedIn: 'root',
  })
 export  class SharedService{



    constructor(private translateService: TranslateService) { }


    
  public getPeriod(days: number): string {

    if (days === 1) {
      return this.translateService.instant('translate_common.day');
    } else if (days === 7) {
      return this.translateService.instant('translate_common.week');
    } else if (days === 30) {
      return this.translateService.instant('translate_common.month');

    }
    else if (days === 90) {
      return this.translateService.instant('translate_common.quarter');
    }
    else if (days === 180) {
      return this.translateService.instant('translate_common.halfYear');
    }
    else if (days === 360 || days === 365) {
      return this.translateService.instant('translate_common.year');
    } else {
      return this.translateService.instant('translate_common.days', { days });
    }

  }

}