<div class="grid gap-4 md:grid-cols-2 mb-10">
  <ng-container *ngFor="let addon of branchAddons; let i = index">
    <div class="rounded-2xl bg-white shadow">
      <div class="pt-6 px-4">
        <div class="flex">
          <div class="flex-1">
            <div class="font-semibold mb-1">
              {{ addon.addOnName }}
            </div>
            <!-- <div class="text-gray-400 text-xs">
              {{ addon.description }}
            </div> -->
          </div>
          <div class="flex mb-6">
            <span class="text-2xl font-bold">
              {{ addon.addOnPrice | currency: 'CAD' : addon.addOnCurrency}}
            </span>
          
            <span class="text-gray-400 text-xs font-medium pt-3">
             
              {{ calculateDateDifference(addon.startDate,addon.endDate)  }}
               
            </span>
          </div>
        </div>
      </div>
      <div class="m-4 h-[1px] bg-gray-300">
        <div class="-translate-y-[1px] h-[3px] rounded-full"
          [style.width]="( calculateDateDifference(addon.startDate,addon.endDate) - ( calculateLeftDays(addon.endDate)   )) / calculateDateDifference(addon.startDate,addon.endDate) * 100 + '%' "
          [ngClass]="( calculateLeftDays(addon.endDate)  > 0 ? 'bg-cyan-300' : 'bg-red-400')">
        </div>
      </div>
      <div class="flex pb-4 px-6">
        <div class="flex-1">
          <ng-container *ngIf="calculateLeftDays(addon.endDate) > 0">
            <span class="text-gray-400 text-sm font-bold">
              {{ calculateLeftDays(addon.endDate) }}
            </span>
            <span class="text-gray-400 text-xs">
              {{ 'translate_days_to_restarted' | translate }}
            </span>
          </ng-container>
          <ng-container *ngIf="calculateLeftDays(addon.endDate) === 0">
            <button (click)="showPayDialog()" type="button"
              class="bg-primary-700 hover:bg-primary-800 text-white text-sm rounded-full py-2 px-4">
              {{ 'translate_renew' | translate }}
            </button>
          </ng-container>
        </div>
        <button class="text-red-600 hover:text-red-700 text-decoration-none">
          {{ 'translate_deactivate_and_remove' | translate }}
        </button>
      </div>
    </div>
  </ng-container>
</div>
