import {
  Component,
  ComponentRef,
  HostListener,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { SideDialogService } from './common/services';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { GlameraSharedService } from 'glamera-shared';
import { DialogComponent } from './common/components/center-dialog/dialog.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('sideDialog', {
    static: true,
    read: ViewContainerRef,
  })
  public sideDialog!: ViewContainerRef;
  public glameraURL = environment.saasURL;



  public isShowenSideDialog: boolean = false;
  private sideDialogComponent: ComponentRef<any>;
  @ViewChild(DialogComponent) dialogComponent: DialogComponent; 

  private subscriptions: Subscription[] = [];

  public constructor(
    public translateService: TranslateService,
    public shared: GlameraSharedService,
    private router: Router,
    private renderer: Renderer2,
    private sideDialogService: SideDialogService,
    private cookieService: CookieService
  ) {
    var tokenObject = cookieService.get('tokenObject'); 
    if (tokenObject == null || tokenObject == undefined || tokenObject == '') {
      window.location.href = environment.saasURL + '/login';
      return;
    } 
  }

  ngOnInit(): void {
  
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  public changeLanguage(lang: string): void {
    this.translateService.use(lang);
  }





  logOut(): void {
    this.cookieService.delete('tokenObject', '/', `.${environment.RootDomain}`); 
    localStorage.clear();
    window.location.href = environment.saasURL + '/logout'; 
    return;
  }
}
