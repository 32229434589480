import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SubscriptionComponent } from './subscriptions/subscriptions.component';
import { BranchesComponent } from './subscriptions/branches/branches.component';
import { ShowBranchComponent } from './subscriptions/branches/show/show.component';
import { InvoicesComponent } from './subscriptions/invoices/invoices.component';
import { PaymentsComponent } from './subscriptions/payments/payments.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { PlansComponent } from './plans/plans.component';
import { BranchAddonsComponent } from './subscriptions/branches/branch-addons/branch-addons.component';
import { PaymentFailureComponent } from './subscriptions/payment-failure/payment-failure.component';
import { PaymentDoneComponent } from './payment-done/payment-done.component';

const routes: Routes = [
  { path: 'payment-done/:id', component: PaymentDoneComponent },
  { path: 'payment-failure/:id', component: PaymentFailureComponent },
  {
    path: '',
    component: SubscriptionComponent,
    children: [
      { path: 'subscription', component: BranchesComponent },
      { path: 'invoices', component: InvoicesComponent },
      { path: 'payments', component: PaymentsComponent },
      { path: '', redirectTo: 'subscription', pathMatch: 'full' },
    ],
  },
  { path: 'plans', component: PlansComponent },
  { path: 'plans/:id', component: PlansComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'subscription/branches/:id', component: ShowBranchComponent },
  { path: 'subscription/branches/:id/addons', component: BranchAddonsComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
