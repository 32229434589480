import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ending',
})
export class EndingPipe implements PipeTransform {
  public transform(value: string | number, length: number = 4): string {
    value = `${value}`;
    return value.length > length ? value.slice(-length) : value;
  }
}
