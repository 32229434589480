import { Injectable } from '@angular/core';
import { PaymentMethod, PaymentMethodDTO } from '@s-app/common/models';
import { BehaviorSubject, take } from 'rxjs';
import { PaymentCardService } from '../api';

@Injectable({
  providedIn: 'root',
})
export class PaymentCardCacheService {
  public list$: BehaviorSubject<PaymentMethodDTO[]> = new BehaviorSubject<
    PaymentMethodDTO[]
  >([]);

  public constructor(private paymentCardService: PaymentCardService) {
    this.refresh();
  }

  public refresh(): void {
    console.log("test");
    this.paymentCardService
      .list(false)
      .pipe(take(1))
      .subscribe((response: any) => {
        console.log(response);
        this.list$.next(response.result);
      });
  }
}
