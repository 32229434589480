import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'paymentType',
})
export class PaymentTypePipe implements PipeTransform {
  public transform(cardNumber: number): 'visa' | 'mastercard' | undefined {
    const cleanedNumber = cardNumber.toString().replace(/[\s-]/g, '');

    if (
      [13, 16].includes(cardNumber.toString().length) &&
      /^4/.test(cleanedNumber)
    ) {
      return 'visa';
    }

    if (cardNumber.toString().length === 16 && /^5[1-5]/.test(cleanedNumber)) {
      return 'mastercard';
    }

    return undefined;
  }
}
