<div class="failure-page">
  <div class="flex items-center justify-center h-[20rem] rounded-lg w-2/3 bg-red-100">
    <div class="text-center">
      <h1 class="text-4xl text-red-700 mb-4">فشل عملية الدفع!</h1>
      <p class="text-lg text-red-700 mb-6">لم تتم عملية الدفع بنجاح. يمكنك العودة إلى صفحة الدفع لإجراء تغييرات أو المحاولة مرة أخرى.</p>
      <button
        class="bg-red-700 text-white px-6 py-2 rounded-lg hover:bg-red-800 transition-colors duration-300"
        (click)="goToInvoicesPage()"
      >
        العودة إلى صفحة الدفع
      </button>
    </div>
  </div>
</div>
