import { Component } from '@angular/core';

@Component({
  templateUrl: './subscriptions.component.html',
})
export class SubscriptionComponent {
  public subLinks = [
    { name: 'translate_the_branches', path: ['',  'subscription'] },
    { name: 'translate_payments', path: ['',  'invoices'] },
    { name: 'translate_payment_methods', path: ['', 'payments'] },
  ];
}
