<div *ngIf="invoices?.length==0&&datachecked" class="mb-28 text-center">
  <div>
    <i class="fa-solid fa-ban"></i>
  </div>
  <div class="my-3 font-semibold text-3xl text-gray-800">

    {{ 'translate_invoice_not_found' | translate }}
  </div>

</div>

<div *ngIf="invoices.length> 0">
  <div class="flex mb-3">
    <div class="flex-1 mb-4">
      <div class="font-semibold">
        {{ "translate_invoices_title" | translate }}
      </div>
      <div class="text-xs text-gray-400">
        {{ "translate_invoices_subtitle" | translate }}
      </div>
    </div>
    <div>
      <button (click)="downloadAll()" class="btn btn-outline-secondary borde-gray-300">
        <i class="fa-solid fa-cloud-arrow-down me-2"></i>
        {{ "translate_download_all" | translate }}
      </button>
    </div>
  </div>
  <div class="bg-white shadow-sm py-2">
    <div class="d-flex m-3">
      <div class="position-relative flex-grow-1 mr-3">
        <i class="fa-solid fa-magnifying-glass position-absolute top-1/2 ltr:left-3 rtl:right-3 -translate-y-1/2"></i>
        <input type="text" class="form-control border border-gray-300 rounded-full px-5"
          [placeholder]="'translate_invoices_search_placeholder' | translate" (input)="searchFreeText($event)" />
      </div>
      <div ngbDropdown>
        <button *ngIf="branches" ngbDropdownToggle
          class="py-2 px-3 border border-gray-300 text-gray-500 hover:bg-gray-500 hover:text-white rounded-full me-3">
          <span *ngIf="searchedBranch"> {{ searchedBranch  }}</span>
          <span *ngIf="!searchedBranch">   {{ "translate_all_branches" | translate }}</span>
        
          <i class="fa-solid fa-angle-down ml-2"></i>
        </button>

        <div class="menu" ngbDropdownMenu>

          <button  (click)="searchBranch(-1,null)"   ngbDropdownItem>{{'translate_all_branches'|translate}}</button>
          <button (click)="searchBranch(branch.id,branch.name)" *ngFor="let branch of branches" ngbDropdownItem>{{branch.name}}</button>
        
        </div>

      </div>
    </div>
    <table class="w-full">
      <thead class="bg-teal-50 [&_th]:font-medium [&_th]:text-xs [&_th]:p-2">
        <tr class="[&_th]:text-gray-500">
          <th>

          </th>
          <th class="ps-3">
            {{ "translate_the_invoice" | translate }}
          </th>

          <th>
            {{ "translate_the_date" | translate }}
          </th>
          <th *ngIf="!branch">
            {{ "translate_the_branch" | translate }}
          </th>
          <th>
            {{ "translate_the_price" | translate }}
          </th>
          <th>
            {{ "translate_actions" | translate }}
          </th>
        </tr>
      </thead>
      <tbody
        class="[&_td]:font-semibold [&_td]:p-2 [&_td]:text-xs [&_td]:text-gray-600 [&_tr.invoice-header]:border-t [&_tr:first-child]:border-t-0 [&_tr]:border-gray-300">
        <ng-container *ngFor="let invoice of invoices">

          <tr class="invoice-header">
            <td>
              <i class="fa-solid text-2xl hover:text-gray-500 cursor-pointer"
                [ngClass]="{'fa-circle-chevron-down': !expandedInvoices[invoice.code], 'fa-circle-chevron-up': expandedInvoices[invoice.code]}"
                (click)="toggleInvoiceDetails(invoice.code)"></i>

            </td>
            <td>
              <img src="assets/img/icons/files/pdf.svg" class="inline me-2" height="40" width="40" />
              {{ invoice.code }}
              <span class="ms-2 px-2 py-1   font-normal rounded-full" [ngClass]="
                  invoice.isPaid
                    ? '  text-green-700'
                    : '  text-red-400'
                ">
                <ng-container *ngIf="invoice.isPaid">
                  <i class="fa fa-check fa-fw"></i>
                  {{ "translate_paid" | translate }}
                </ng-container>
                <ng-container *ngIf="!invoice.isPaid">
                  <i class="fa fa-xmark fa-fw"></i>
                  {{ "translate_unpaid" | translate }}
                </ng-container>
              </span>
            </td>

            <td>
              {{ invoice.billingDate | date }}
            </td>
            <td *ngIf="!branch && invoice.billingItems[0].branchId">
              -
            </td>
            <td>
              {{ invoice.totalCost | number : "1.1" }}
              {{ invoice.currency.code }}
            </td>
            <td>
              <button *ngIf="!invoice.isPaid" type="button" (click)="
                  showPayment(
                    invoice,
                    invoice.billingItems[0],
                    invoice.billingItems[0].branchId,
                    true
                  )
                " class="text-white bg-primary-500 hover:bg-primary-700 rounded-full px-3 py-2 font-normal">
                {{ "translate_pay_now" | translate }}
              </button>
              <ng-container *ngIf="invoice.isPaid">
                <button type="button" (click)="showInvoice(invoice.code)"
                  class="rounded-md hover:bg-primary-700 hover:text-white hover:border-primary-700 border-1 border-gray-300 p-2 text-gray-700 me-2">
                  <i class="fa-regular fa-eye"></i>
                </button>
                <button type="button" (click)="downloadInvoiceAsPdf(invoice)"
                  class="rounded-md hover:bg-primary-700 hover:text-white hover:border-primary-700 border-1 border-gray-300 p-2 text-gray-700">
                  <i class="fa-solid fa-cloud-arrow-down"></i>
                </button>
              </ng-container>
            </td>
          </tr>
          <ng-container *ngIf="expandedInvoices[invoice.code]">
            <tr *ngFor="let item of invoice.billingItems">
              <td class="text-center">
                <span *ngIf="!item.isPaid">
                  <input type="checkbox" (change)="selectBillingItem($event, item.billingId)"
                    class="text-primary-500 rounded-sm" />
                </span>

              </td>
              <td>
                <span>{{ item.title }}</span>
                <span *ngIf="item.cycle"> : {{ sharedService.getPeriod(item.cycle) |translate }}</span>
                <span *ngIf="!item.cycle"> : {{ 'translate_onetime_payment' |translate }}</span>
              </td>


              <td>
                {{ item.billingDate | date }}
              </td>
              <td>
                <span class="brnachInfo" *ngIf="!branch && item.branchId">
                  {{item.branchId}}
                  <i [title]="item.branchName" class="fa-solid fa-circle-info"></i>
                </span>
                <span *ngIf="!branch && !item.branchId"> <i title="{{'Allbranches'|translate}}"
                    class="fa-solid fa-circle-info"></i> </span>
              </td>
              <td class="opacity-80">
                {{ item.cost | number : "1.1" }}
                {{ item.currency.code }}
              </td>
              <td>


                <span class="ms-2 px-2 py-1   font-normal rounded-full" [ngClass]="
                item.isPaid
                    ? 'text-green-700'
                    : 'text-red-400'
                ">
                  <ng-container *ngIf="item.isPaid">
                    <i class="fa fa-check fa-fw"></i>
                    {{ "translate_paid" | translate }}
                  </ng-container>
                  <ng-container *ngIf="!item.isPaid">
                    <i class="fa fa-xmark fa-fw"></i>
                    {{ "translate_unpaid" | translate }}
                  </ng-container>
                </span>

                <!-- <button
                  *ngIf="!invoice.isPaid"
                  type="button"
                  (click)="showPayment(invoice, item, item.branchId, false)"
                  class="text-white bg-primary-500 hover:bg-primary-700 rounded-full px-3 py-2 font-normal"
                >
                  {{ "translate_pay_now" | translate }}
                </button> -->
              </td>


              <!-- 
              <td>  <ng-container *ngIf="invoice.isPaid">
                  <button
                    type="button"
                    class="rounded-md hover:bg-primary-700 hover:text-white hover:border-primary-700 border-1 border-gray-300 p-2 text-gray-700 me-2"
                  >
                    <i class="fa-regular fa-eye"></i>
                  </button>
                </ng-container>
              </td> -->
            </tr>
          </ng-container>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>
<div class="hidden">
  <div #invioceDetails>
    <div *ngIf="selectedInvoice">
      <button  (click)="downloadInvoiceAsPdf(selectedInvoice)"
        class="m-2 text-gray-500 border border-gray-300 bg-gray-50 hover:bg-gray-500 hover:text-white p-2 rounded-md float-end">
        <i class="fa-solid fa-cloud-arrow-down me-2"></i>
        {{ "translate_download" | translate }}
      </button>
      <div class="clear-both pt-2 border-b-2 border-dashed border-gray-300">
        <div class="flex gap-3 p-8 pt-0">
          <div class="bg-gray-100 rounded-b-xl p-4 flex align-items-end">
            <img src="assets/img/logo/logo.svg" width="36" height="36" />
          </div>
          <div class="flex-1 rtl:text-right ltr:text-left">
            <div class="text-primary-400 font-bold text-3xl mt-8 mb-3">
              {{ ("translate_invoice" | translate).toUpperCase() }}
            </div>
            <div class="grid grid-cols-2 gap-3 text-gray-400 text-sm">
              <div>
                <div class="mb-2">
                  {{ selectedInvoice.billingItems[0].invoiceNo }}
                </div>
                <div class="mb-2">
                  {{ "translate_invoice_date" | translate }}:
                  {{ selectedInvoice.billingDate | date }}
                </div>
              </div>
              <div>
                <div class="mb-2">
                  {{ "translate_company_id" | translate }}:
                  {{ selectedInvoice.companyId }}
                </div>
                <div>
                  {{ "translate_status" | translate }}:
                  {{
                  "translate_" +
                  (selectedInvoice.isPaid ? "paid" : "unpaid") | translate
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mx-8 mt-3 text-gray-800 rtl:text-right ltr:text-left text-xs">
        <table class="w-full [&_td]:px-2">
          <thead class="bg-teal-50 [&_td]:py-3">
            <tr class="text-nowrap">
              <td>
                {{ "translate_item" | translate }}
              </td>
              <td>
                {{ "translate_cost" | translate }}
              </td>
              <td>
                {{ "translate_branch_id" | translate }}
              </td>
              <td>
                {{ "translate_currency" | translate }}
              </td>
              <td class="ltr:text-right rtl:text-left">
                {{ "translate_total_price" | translate }}
              </td>
            </tr>
          </thead>
          <tbody class="[&_td]:py-2">
            <tr *ngFor="let item of selectedInvoice.billingItems" class="border-b-2 border-gray-200">
              <td class="font-semibold">{{ item.title }}</td>
              <td>
                {{ item.cost | number : "1.1" }}
              </td>
              <td>
                {{ item.branchId }}
              </td>
              <td>
                {{ item.currency.code }}
              </td>
              <td class="ltr:text-right rtl:text-left">
                {{ item.cost | number : "1.1" }}
              </td>
            </tr>
          </tbody>
          <tbody class="text-sm font-medium [&_td]:py-3">
            <tr>
              <td colspan="3"></td>
              <td class="text-gray-500">
                {{ "translate_subtotal" | translate }}
              </td>
              <td class="ltr:text-right rtl:text-left">
                {{ selectedInvoice.totalCost | number : "1.1" }}
                {{ selectedInvoice.currency.code }}
              </td>
            </tr>
            <tr>
              <td colspan="3"></td>
              <td class="text-gray-500 border-b-2">
                {{ "translate_tax" | translate }}
              </td>
              <td class="ltr:text-right rtl:text-left border-b-2">
                0
                <!-- Assuming no tax for simplicity -->
                {{ selectedInvoice.currency.code }}
              </td>
            </tr>
            <tr>
              <td colspan="3"></td>
              <td class="text-gray-500 border-b-2">
                {{ "translate_total" | translate }}
              </td>
              <td class="ltr:text-right rtl:text-left border-b-2">
                {{ selectedInvoice.totalCost | number : "1.1" }}
                {{ selectedInvoice.currency.code }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="mx-8 mt-16 ltr:float-right rtl:float-left">
        <span class="text-xs text-gray-950 me-2">
          {{ "translate_power_by" | translate }}:
        </span>
        <img src="assets/img/logo-text.svg" class="inline-block h-5" />
      </div>
    </div>
  </div>
</div>
<!-- Payment Method Popup -->
<div *ngIf="showPaymentPopup" class="popup">
  <div class="popup-content">
    <h2>{{'translate_select_payment_method'|translate }}</h2>
    <div class="payment-options">
      <button (click)="selectPaymentMethod('Mada')" [ngClass]="{ selected: selectedPaymentMethod === 'Mada' }">
        <img src="../../../assets/mada.png" alt="Mada" class="payment-logo" />
        Mada
      </button>
      <button (click)="selectPaymentMethod('Visa')" [ngClass]="{ selected: selectedPaymentMethod === 'Visa' }">
        <img src="../../../assets/visa.png" alt="Visa" class="payment-logo visa-logo" />
        Visa
      </button>
    </div>
    <div class="popup-actions">
      <button (click)="submitPaymentMethod()" class="submit-btn">Submit</button>
      <button (click)="closePaymentPopup()" class="cancel-btn">Cancel</button>
    </div>
  </div>
</div>