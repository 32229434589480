import { Component, OnInit, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PlansService } from '@bl-src/app/core/services/plans.service';
import Swal from 'sweetalert2';
import { GlameraSharedService } from 'glamera-shared';
import { ActivatedRoute } from '@angular/router';
import { BillingService } from '@bl-src/app/core/services/billing.service';
import { SharedService } from '@s-app/common/services/shared.service';
import { SideDialogService } from '@s-app/common/services';

@Component({
  selector: 'app-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.scss'],
})
export class PlansComponent implements OnInit, AfterViewInit {
  public planApi: any[] = [];
  public featuresApi: any[] = [];
  public errorMessage: string = '';
  public selectedPayPeriodApi: string = 'cycle30';
  public selectedPeriodNameApi: string = 'Month';
  public showPopup: boolean = false;
  public selectedBranchId: number | null = null;
  public subscriptionPlans: any[] = [];
  private companyId = 0;
  private token = "";

  public middleIndex: number;
  public hasIdInUrl: boolean = false;
  public currentBranchId: number | null = null;
  public selectedBillingId: number | null = null;
  public selectedPaymentMethod: string = 'Mada';
  public showPaymentPopup: boolean = false;
  public selectedBillingCode: string | null = null;
  public subscriptionDetails: any;
  public subscriptionData: any;

  public periodsApi = [
    { code: 'cycle30', billingName: 'Monthly' },
    { code: 'cycle90', billingName: 'Quarterly' },
    { code: 'cycle360', billingName: 'Yearly' }
  ];

  nonSubscribedBranches: any;


  constructor(
    private http: HttpClient,
    private plansService: PlansService,
    private sharedService: GlameraSharedService,
    private route: ActivatedRoute,
    private billingService: BillingService,
    public shared:  SharedService ,
    private sideDialogService: SideDialogService
  ) {
    this.companyId = sharedService.company.id;
    this.token = sharedService.token ?? "";
  }


  public showPayment(id?: number): void {
    debugger;
    if (this.currentBranchId !== null) {
      if (this.currentBranchId) {


        this.selectedPlanPriceId = id ?? 0;
        this.selectedBranchId = this.currentBranchId;

        var subscriptionData = this.loadSubscriptionDetails();
        this.plansService.subscribeToPlan(subscriptionData).subscribe(response => {
          if (response && response.billingId) {
            console.log('Billing Id:', response.billingId);
            this.selectedBillingId = response.billingId;
            this.showPaymentPopup = true;
          } else {
            console.log('Billing Code not found in the response');
          }
        }, error => {
          console.error('Subscription error:', error);
        });
      } else {
        console.log('Branch not found');
      }
    }
  }



  public closePaymentPopup(): void {
    this.showPaymentPopup = false;
  }
  public selectPaymentMethod(method: string): void {
    this.selectedPaymentMethod = method;
  }
  public submitPaymentMethod(): void {
    if (!this.selectedPaymentMethod) {
      console.log('Please select a payment method.');
      return;
    }
    var paymentData = {};
    if (this.selectedBillingCode) {
      paymentData = {
        BillingCode: this.selectedBillingCode,
        PaymentMethodEnum: this.selectedPaymentMethod === 'Mada' ? 2 : 0
      }
    }
    else if (this.selectedBillingId) {
      paymentData = {
        BillingId: this.selectedBillingId,
        PaymentMethodEnum: this.selectedPaymentMethod === 'Mada' ? 2 : 0
      };
    }
    this.billingService.postPayment(paymentData).subscribe(
      (response) => {
        console.log('Payment successful:', response);
        if (response.paymentProviderURL) {
          window.location.href = response.paymentProviderURL;
        }
        this.showPaymentPopup = false;
      },
      (error: any) => {
        console.error('Payment error:', error);
      }
    );
  }




  ngOnInit(): void {

    var branchId = this.route.snapshot.paramMap.get('id');

    if (branchId != null && branchId != "" && branchId != undefined) {
      this.currentBranchId = parseInt(branchId);
    }


    this.plansService.getPlans().subscribe(
      (data) => {
        this.planApi = data.packageFeatures;
        this.sortPlansByOrder();
        this.extractFeatures();
        this.getNonSubscribedBranches();
        this.middleIndex = Math.floor(this.planApi.length / 2);
      },
      (error) => {
        this.errorMessage = 'Error loading company add-ons';
        console.error(error);
      }
    );
  }

  loadSubscriptionDetails() {
    let cycle = 30; // default to monthly
    if (this.selectedPayPeriodApi === 'cycle90') {
      cycle = 90; // quarterly
    } else if (this.selectedPayPeriodApi === 'cycle360') {
      cycle = 360; // yearly
    }

    if (this.selectedPlanPriceId == 0 || this.selectedPlanPriceId == null) {

      this.closePopup();
      throw Error('Please select a plan.');
    }
    const subscriptionPlanPriceId = this.selectedPlanPriceId

    const subscriptionData = {
      "SubscriptionPlanPriceId": subscriptionPlanPriceId,
      "CompanyId": this.companyId,
      "BranchId": this.selectedBranchId,
      "Cycle": cycle,
    };
    return subscriptionData;
  }

  sortPlansByOrder(): void {
    this.planApi.sort((a, b) => a.order - b.order);
  }

  extractFeatures(): void {
    const allFeatures = new Set<string>();
    this.planApi.forEach(plan => {
      plan.features.forEach((feature: any) => {
        allFeatures.add(feature.name);
      });
    });
    this.featuresApi = Array.from(allFeatures);
  }

  isFeatureExist(features: any[], featureName: string): boolean {
    return features.some(feature => feature.name === featureName && feature.isExist);
  }

  getPlanPrice(plan: any): number {
    return plan[this.selectedPayPeriodApi].price;
  }

  onPeriodChange(periodCode: string, periodName: string): void {
    this.selectedPayPeriodApi = periodCode;
    this.selectedPeriodNameApi = periodName;
  }

  getNonSubscribedBranches(): void {
    this.plansService.getNonSubscribedBranches().subscribe(
      data => {
        this.nonSubscribedBranches = data;
        console.log('Non-subscribed Branches:', this.nonSubscribedBranches);
      },
      error => {
        console.error('Error fetching non-subscribed branches:', error);
      }
    );
  }

  getSubscriptionPlans(): void {
    this.plansService.getSubscriptionPlans().subscribe(
      data => {
        this.subscriptionPlans = data.result;
        console.log('Subscription Plans:', this.subscriptionPlans);
      },
      error => {
        console.error('Error fetching subscription plans:', error);
      }
    );
  }
  selectedPlanPriceId = 0;
  openPopup(id?: number): void {
    //this.showPopup = true;
    this.selectedPlanPriceId = id ?? 0; 
    debugger;
    this.sideDialogService.showBuyPlanDialog({side:'center', branchId:this.currentBranchId}); 
  }

  closePopup(): void {
    this.showPopup = false;
  }

  submitBranch(): void {


    if (this.selectedBranchId !== null) {

      const subscriptionData = this.loadSubscriptionDetails();

      this.plansService.subscribeToPlan(subscriptionData).subscribe(response => {
        console.log('Subscription response:', response);
        Swal.fire({
          title: 'Success!',
          text: 'تم الإشتراك بنجاح',
          icon: 'success',
          confirmButtonText: 'OK'
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = '/subscription';
          }
        });
      }, error => {
        console.error('Subscription error:', error);
        Swal.fire({
          title: 'Error!',
          text: 'حصلت مشكلة أثناء الإشتراك',
          icon: 'error',
          confirmButtonText: 'OK'
        });
      });

      this.closePopup();
    } else {
      Swal.fire({
        title: 'Error!',
        text: 'Please select a branch.',
        icon: 'error',
        confirmButtonText: 'OK'
      });
    }
  }

  selectBranch(branchId: number): void {
    this.selectedBranchId = branchId;
  }

  @ViewChild('tracking')
  private tracking: ElementRef;

  public periods: {
    code: 'monthly' | 'quarterly' | 'yearly';
    billingName: string;
  }[] = [
      { code: 'monthly', billingName: 'Monthly Billing' },
      { code: 'quarterly', billingName: 'Quarterly Billing' },
      { code: 'yearly', billingName: 'Yearly Billing' },
    ];

   public getPeriodName(periodCode: string): string {
      switch (periodCode) {
        case 'cycle30':
          return 'Month';
        case 'cycle90':
          return 'Quarter';
        case 'cycle360':
          return 'Year';
        default:
          return '';
      }
    }

  public get selectedPeriodName(): string {
    switch (this.selectedPayPeriod) {
      case 'yearly':
        return 'Year';
      case 'quarterly':
        return 'Quarter';
      case 'monthly':
        return 'Month';
      default:
        return '';
    }
  }

  public selectedPayPeriod: 'monthly' | 'quarterly' | 'yearly' = 'yearly';

  public isFixedPlansHeader: boolean = false;

  public ngAfterViewInit(): void {
  }

  public paymentPeriods: string[] = ['monthly', 'quarterly', 'yearly'];

  public questions: { question: string; answer: string }[] = [
    {
      question: 'What payment methods do you accept?',
      answer:
        'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.',
    },
    {
      question: 'Are there any hidden fees or extra charges?',
      answer:
        'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.',
    },
    {
      question: 'Can I upgrade or downgrade my plan at any time?',
      answer:
        'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.',
    },
    {
      question: 'Is there a free trial available?',
      answer:
        'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.',
    },
  ];

  public features: string[] = [];
}
