import { Injectable } from '@angular/core';
import { EditComponent } from '@s-app/subscriptions/payments/methods/edit/edit.component';
import { PayComponent } from '@s-app/subscriptions/payments/methods/pay/pay.component';
import { BehaviorSubject } from 'rxjs';
import { PaymentMethod } from '@s-app/common/models';
import { BuyPlanComponent } from '@s-app/subscriptions/buy-plan/buy-plan.component';

@Injectable({
  providedIn: 'root',
})
export class SideDialogService {
  public showDialogSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public sideDialogComponent: BehaviorSubject<{
    component: any;
    data: { [key: string]: any };
  } | null> = new BehaviorSubject<{
    component: any;
    data: { [key: string]: any };
  } | null>(null);


  public showDialog(component:any ,data?:any): void {
    this.sideDialogComponent.next({
      component: component,
      data: data ?? {},
    });
  }

  
  public showBuyPlanDialog(data?:any): void {
    console.log('showBuyPlanDialog')
    this.sideDialogComponent.next({
      component: BuyPlanComponent, 
      data:  data ??{}, 
    });
  }


  public showPayDialog(): void {
    this.sideDialogComponent.next({
      component: PayComponent,
      data: {},
    });
  }

  public showDifferentMethodDialog(): void {
    this.sideDialogComponent.next({
      component: EditComponent,
      data: { mode: 'different' },
    });
  }

  public showEditMethodDialog(paymentMethod: PaymentMethod): void {
    this.sideDialogComponent.next({
      component: EditComponent,
      data: { mode: 'edit', paymentMethod },
    });
  }

  public showNewMethodDialog(): void {
    this.sideDialogComponent.next({
      component: EditComponent,
      data: { mode: 'new' },
    });
  }
}
