import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { BillingService } from './../../../../../glamera-booking-link/src/app/core/services/billing.service'; 
import { SideDialogService } from '@s-app/common/services';
import { Invoice, BillingItem } from '@bl-src/app/core/models/invoice.model';
import Swal from 'sweetalert2';
import { SharedService } from '@s-app/common/services/shared.service';
import { BranchService } from '@bl-src/app/core/services/branch.service';
import jsPDF from 'jspdf';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';



@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss'],
  
})
export class InvoicesComponent implements OnInit {
  @Input()
  public branch: any | null = null;
  public branches: any []=[];
  public datachecked=false

  @ViewChild('invioceDetails')
  public invioceDetails!: ElementRef;

  public invoices: Invoice[] = [];
  public originalInvoices: Invoice[] = [];
  public selectedInvoice: Invoice | undefined;
  public selectedBillingItems: number[]=[];
  public selectedPaymentMethod: string = 'Mada';
  public showPaymentPopup: boolean = false;
  public isPrimaryButton: boolean = false;
  public expandedInvoices: { [key: string]: boolean } = {};

  constructor(
    private billingService: BillingService,
    private  branchService: BranchService,
    private sideDialogService: SideDialogService,
    public sharedService: SharedService

  ) {}

  ngOnInit() {
    this.showInvoices();
    this.getAllBranches();
  }

  public showInvoices(criteria?:any): void {
    this.billingService.getBillings(criteria).subscribe(
      (data: { result: Invoice[] }) => {
        this.invoices = data.result;
        this.originalInvoices = data.result ;
        this.datachecked=true;
        
      },
      (error: any) => {
        console.error('Error fetching billings:', error);
      }
    );
  }

  public getAllBranches(): void {
    this.branchService.getAllLiteBranches().subscribe(
      (data:  any ) => {
          this.branches=data;
      },  
      (error: any) => {
        console.error('Error fetching billings:', error);
      }
    );
  }

  public searchFreeText($event:any): void {
    let freeText = $event.target.value;  
      this.invoices = this.originalInvoices.filter((invoice) => 
        invoice.billingItems.some(i=>   i.title.toLowerCase().includes(freeText.toLowerCase()) || i.invoiceNo.toString().toLowerCase().includes(freeText.toLowerCase()) )  
    || invoice.code.toString().toLowerCase().includes(freeText.toLowerCase() 
    )); 
  }
 
  public searchBranchId($event:any): void {
    let branchId = $event.target.value;  
      this.invoices = this.originalInvoices.filter((invoice) => invoice.billingItems.some(i=>i.branchId == branchId)); 
  }
  searchedBranch:string | null;
  public searchBranch(id:any,name:string|null): void {
    let branchId = id;  
    if(branchId==-1){
      this.searchedBranch=null;
      this.invoices = this.originalInvoices;
      return;
    }
    this.searchedBranch=name;
      this.invoices = this.originalInvoices.filter((invoice) => invoice.billingItems.some(i=>i.branchId == branchId)); 
    
  }

  public searchMonths($event:any): void {
    let date = $event.target.value;
    let month = new Date(date).getMonth() + 1;
    let year =  new Date(date).getFullYear();
  
      this.invoices = this.originalInvoices.filter((invoice) => invoice.billingItems.some(i=>i.billingDate 
        && new Date(i.billingDate).getMonth() + 1 == month 
        && new Date(i.billingDate).getFullYear() == year)); 

  }

  public showPayment(invoice: any, billingItems: any, branchId: any, isPrimaryButton: boolean): void {
    this.selectedInvoice = invoice;
   // this.selectedBillingItems = billingItems;
    this.isPrimaryButton = isPrimaryButton;
    this.showPaymentPopup = true;
  }

  selectBillingItem(event:any,billingId:number){
    if(event.target.checked){
      this.selectedBillingItems.push(billingId);

    }else{
      this.selectedBillingItems = this.selectedBillingItems.filter((item)=>item!=billingId);
    }

    
  }

  public submitPaymentMethod(): void {

    console.log('selectedBillingItems:', this.selectedBillingItems);
    if (this.selectedPaymentMethod && this.selectedInvoice   ) {


      const paymentData = 
      this.isPrimaryButton && this.selectedBillingItems.length>0

        ? 
        { 
          BillingIds: this.selectedBillingItems, 
          PaymentMethodEnum: this.selectedPaymentMethod === 'Mada' ? 2 : 0
        }
        :
        
        {
            BillingCode: this.selectedInvoice.code,  
            PaymentMethodEnum: this.selectedPaymentMethod === 'Mada' ? 2 : 0
          }
      ;
        

      this.billingService.postPayment(paymentData).subscribe(
        (response) => {
          console.log('Payment successful:', response);
          if (response.paymentProviderURL) {
            window.location.href = response.paymentProviderURL;
          }
        },
        (error: any) => {
          console.error('Payment error:', error);
        }
      );
      this.showPaymentPopup = false;
    } else {
      console.log('Please select a payment method.');
    }
  }

  public selectPaymentMethod(method: string): void {
    this.selectedPaymentMethod = method;
  }

  public closePaymentPopup(): void {
    this.showPaymentPopup = false;
  }

 

 
  public showInvoice(id: string): void {
    this.selectedInvoice = this.invoices.find((invoice) => invoice.code === id);
    if (!this.selectedInvoice) {
      return;
    }
    Swal.fire({
      html: this.invioceDetails.nativeElement,
      showConfirmButton: false,
      showCloseButton: true,
      closeButtonHtml:
        '<i class="fa-solid fa-xmark text-xl text-gray-800"></i>',
      width: 'min(47rem, 100%)',
      customClass: {
        htmlContainer: 'm-0',
        closeButton: 'absolute left-0',
      },
    });
  }

  public toggleInvoiceDetails(invoiceCode: string): void {
    this.expandedInvoices[invoiceCode] = !this.expandedInvoices[invoiceCode];
  }
  public async downloadAll(): Promise<void> {
    const zip = new JSZip();
  
    // Generate each invoice PDF and add to the ZIP
    for (const invoice of this.invoices) {
      const pdfData = await this.generateInvoicePdf(invoice);
      zip.file(`Invoice_${invoice.code}.pdf`, pdfData, { binary: true });
    }
  
    // Generate ZIP and trigger download
    zip.generateAsync({ type: 'blob' }).then((zipFile) => {
      saveAs(zipFile, 'Invoices.zip');
    });
  }
  
  // Function to generate invoice PDF and return it as binary data
  public generateInvoicePdf(invoice: Invoice): Promise<Blob> {
    return new Promise((resolve) => {
      const doc = new jsPDF();
  
      doc.setFontSize(16);
      doc.text('Invoice Details', 10, 10);
      doc.setFontSize(12);
      doc.text(`Invoice Number: ${invoice.code}`, 10, 20);
      doc.text(`Date: ${new Date(invoice.billingDate).toLocaleDateString()}`, 10, 30);
      doc.text(`Total Cost: ${invoice.totalCost.toFixed(2)} ${invoice.currency.code}`, 10, 40);
      doc.text(`Paid: ${invoice.isPaid ? 'Yes' : 'No'}`, 10, 50);
  
      doc.setFontSize(14);
      doc.text('Billing Items:', 10, 60);
      doc.setFontSize(12);
  
      invoice.billingItems.forEach((item, index) => {
        const position = 70 + (index * 10);
        doc.text(`- ${item.title}`, 10, position);
        doc.text(`Price: ${item.cost.toFixed(2)} ${item.currency.code}`, 100, position);
      });
  
      // Output the PDF as binary string data
      const pdfData = doc.output('blob'); // Create Blob from jsPDF
      resolve(pdfData);
    });
  }


  public downloadInvoiceAsPdf(invoice: Invoice): void {
    const doc = new jsPDF();

    doc.setFontSize(16);
    doc.text('Invoice Details', 10, 10);
    doc.setFontSize(12);
    doc.text(`Invoice Number: ${invoice.code}`, 10, 20);
    doc.text(`Date: ${new Date(invoice.billingDate).toLocaleDateString()}`, 10, 30);
    doc.text(`Total Cost: ${invoice.totalCost.toFixed(2)} ${invoice.currency.code}`, 10, 40);
    doc.text(`Paid: ${invoice.isPaid ? 'Yes' : 'No'}`, 10, 50);

    doc.setFontSize(14);
    doc.text('Billing Items:', 10, 60);
    doc.setFontSize(12);

    invoice.billingItems.forEach((item, index) => {
      const position = 70 + (index * 10);
      doc.text(`- ${item.title}`, 10, position);
      doc.text(`Price: ${item.cost.toFixed(2)} ${item.currency.code}`, 100, position);
    });

    doc.save(`Invoice_${invoice.code}.pdf`);
  }

}
