import { Injectable } from '@angular/core';
import { Branch, PaymentMethod } from '@s-app/common/models';
import { BehaviorSubject, take } from 'rxjs'; 
import { BranchService } from '@bl-src/app/core/services/branch.service';

@Injectable({
  providedIn: 'root',
})
export class BranchCacheService {
  public list$: BehaviorSubject<Branch[]> = new BehaviorSubject<
      Branch[]
  >([]);

  public triggerd$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public constructor(    private branchService: BranchService ) {
    this.refresh();
  }

  public refresh(): void {
    
    this.triggerd$.next(true);
    // this.branchService.getBranchesSubscriptions().pipe(take(1)).subscribe((response: any) => {
    //   const updatedData = response.data;
  
    //   // Get the current list from the BehaviorSubject
    //   let currentList = this.list$.getValue();
  
    //   // If `lastId` is provided, find and update the corresponding item in the list
    //   if (lastId) {
    //     currentList = currentList.map((branch) =>
    //       branch.branchId === lastId ? { ...branch, ...updatedData } : branch
    //     );
    //   } else {
    //     // If no `lastId` is provided, simply replace the list with the updated data
    //     currentList = updatedData;
    //   }
  
    //   // Emit the updated list
    //   this.list$.next(currentList);
    // });
  }
}
