import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class GlameraSharedService {

  constructor( private cookieService : CookieService) {

     
   }
    
   get  token(){
    var tokenObject = this.cookieService.get('tokenObject'); 
   


 
    if(tokenObject){

      var tokenObjectData = JSON.parse(tokenObject);
      return tokenObjectData.token;
    }
    else 
    return null; 
  }



  get  userIdentity(){
  
    var tokenObject = this.cookieService.get('tokenObject'); 

  
  

    if(tokenObject){
      var tokenObjectData = JSON.parse(tokenObject);
      return  tokenObjectData.user  ;
    }
    else 
    return null; 
  }

      
  get  company(){

      
    var tokenObject = this.cookieService.get('tokenObject'); 
   
  

 
    if(tokenObject){
      var tokenObjectData = JSON.parse(tokenObject);
       return  tokenObjectData.company ;
    }
    else 
    return null; 
  }
 

  
}
