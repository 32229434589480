<div class="relative min-h-full pb-16">
  <div class="mb-10">
    <i (click)="mode === 'different' ? showPayDialog() : ''"
      class="fa-solid fa-arrow-left-long rtl:rotate-180 px-2 pe-2"
      [ngClass]="{'cursor-pointer': mode === 'different'}"></i>
    <span class="font-bold">
      {{ (mode == 'edit' ? 'translate_payment_method' : 'translate_add_new_payment') | translate }}
    </span>
  </div>
  <div class="text-sm mb-6">
    {{ 'translate_card_details' | translate }}
  </div>
  <form [formGroup]="form" class="text-xs" (ngSubmit)="submit()">
    <div class="relative">
      <app-form-grid-spinner [isShown]="isSubmitting"></app-form-grid-spinner>
      <div class="grid grid-cols-2 gap-4">
        <div class="mb-2 pb-3 relative">
          <label class="block mb-2">
            {{ 'translate_name_on_card' | translate }}
          </label>
          <input [ngClass]="{'border-red-700': form.get('HolderName')?.invalid && form.get('HolderName')?.touched}"
            type="text" class="block w-full border-gray-300 hover:border-primary-700 rounded-md py-2 px-3 text-xs"
            formControlName="HolderName" [placeholder]="'translate_enter_name' | translate" />
          <div *ngIf="form.get('HolderName')?.hasError('required') && form.get('HolderName')?.touched"
            class="text-red-500 text-xs font-normal absolute mt-1">
            {{ 'translate_holder_name_required' | translate }}
          </div>
        </div>
        <div class="mb-2 pb-3 relative">
          <label class="block mb-2">
            {{ 'translate_expiry' | translate }}
          </label>
          <input type="text" mask="M0/00"
            [ngClass]="{'border-red-700': form.get('expiration-date')?.invalid && form.get('expiration-date')?.touched}"
            class="block w-full border-gray-300 hover:border-primary-700 rounded-md py-2 px-3 text-xs"
            formControlName="expiration-date" placeholder="MM/YY" />
          <div *ngIf="form.get('expiration-date')?.hasError('required') && form.get('expiration-date')?.touched"
            class="text-red-500 text-xs font-normal absolute mt-1">
            {{ 'translate_expiration_date_required' | translate }}
          </div>
          <div *ngIf="form.get('expiration-date')?.hasError('pattern') && form.get('expiration-date')?.touched"
            class="text-red-500 text-xs font-normal absolute mt-1">
            {{ 'translate_expiration_date_invalid' | translate }}
          </div>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-4">
        <div class="mb-2 pb-3 relative">
          <label class="block mb-2">
            {{ 'translate_card_number' | translate }}
          </label>
          <input type="text" mask="0000-0000-0000-0000"
            [ngClass]="{'border-red-700': form.get('CardNumber')?.invalid && form.get('CardNumber')?.touched}"
            class="block w-full border-gray-300 hover:border-primary-700 rounded-md py-2 px-3 text-xs"
            formControlName="CardNumber" placeholder="1234-0000-0000-0000" />
          <div *ngIf="form.get('CardNumber')?.hasError('required') && form.get('CardNumber')?.touched"
            class="text-red-500 text-xs font-normal absolute mt-1">
            {{ 'translate_card_number_required' | translate }}
          </div>
          <div *ngIf="form.get('CardNumber')?.hasError('pattern') && form.get('CardNumber')?.touched"
            class="text-red-500 text-xs font-normal absolute mt-1">
            {{ 'translate_card_number_invalid' | translate }}
          </div>
        </div>
        <div class="mb-2 pb-3 relative">
          <label class="block mb-2">
            {{ 'translate_cvv' | translate }}
          </label>
          <input type="text" mask="000" formControlName="CVV"
            [ngClass]="{'border-red-700': form.get('CVV')?.invalid && form.get('CVV')?.touched}"
            class="block w-full border-gray-300 hover:border-primary-700 rounded-md py-2 px-3 text-xs"
            placeholder="***" />
          <div *ngIf="form.get('CVV')?.hasError('required') && form.get('CVV')?.touched"
            class="text-red-500 text-xs font-normal absolute mt-1">
            {{ 'translate_cvv_required' | translate }}
          </div>
          <div *ngIf="form.get('CVV')?.hasError('pattern') && form.get('CVV')?.touched"
            class="text-red-500 text-xs font-normal absolute mt-1">
            {{ 'translate_cvv_invalid' | translate }}
          </div>
        </div>
      </div>
      <div *ngIf="mode === 'different'" class="mb-2">
        <label class="text-sm cursor-pointer">
          <input type="checkbox" class="border-gray-300 hover:border-primary-700 text-teal-500 rounded-sm me-2" />
          {{ 'translate_save_payment_details' | translate }}
        </label>
      </div>
      <!-- <hr class="border-2 border-gray-300 my-6 -mx-4" />
      <div class="text-sm mb-1">
        {{ 'translate_email' | translate }}
      </div> -->
      <!-- <div class="text-gray-500 mb-6">
        {{ 'translate_invoices_will_be_sent_to_this_email_address' | translate }}
      </div> -->
      <!-- <div class="mb-2 pb-3 relative">
        <label class="block mb-2">
          {{ 'translate_email' | translate }}
        </label>
        <input type="email" class="block w-full border-gray-300 hover:border-primary-700 rounded-md py-2 px-3 text-xs"
          [ngClass]="{'border-red-700': form.get('email')?.invalid && form.get('email')?.touched}"
          formControlName="email" [placeholder]="'translate_enter_email' | translate" />
        <div *ngIf="form.get('email')?.hasError('required') && form.get('email')?.touched"
          class="text-red-500 text-xs font-normal absolute mt-1">
          {{ 'translate_email_required' | translate }}
        </div>
        <div *ngIf="form.get('email')?.hasError('email') && form.get('email')?.touched"
          class="text-red-500 text-xs font-normal absolute mt-1">
          {{ 'translate_email_invalid' | translate }}
        </div>
      </div> -->
    </div>
    <div class="border-t-2 border-gray-300 py-6 px-10 text-end absolute bottom-0 -left-10 -right-10 -mb-10">
      <button (click)="hideDialog()" type="button"
        class="py-2 px-3 min-w-28 border-1 text-gray-500 hover:text-primary-800 border-primary-300 hover:border-primary-700 rounded-md me-2">
        {{ 'translate_cancel' | translate }}
      </button>
      <button type="submit"
        class="py-2 px-3 min-w-28 border-1 text-white bg-primary-700 hover:bg-primary-800 border-primary-300 rounded-md">
        {{ 'translate_save' | translate }}
      </button>
    </div>
    <div>
      <div class="text-red-500 text-xs font-normal   mt-1">
        {{ errorMessage }}
      </div>
    </div>
  </form>
</div>
