 
 

<div class="done-page">

  <ng-container *ngIf="status=='pending'">
    <div class="confirmation-container">
      <i class="fa-solid fa-spinner"></i>
      <h2>{{ 'translate_payment_created_successfully' | translate }}</h2>
      <p>{{ 'translate_confirm_payment' | translate }}</p> 
    </div>
  </ng-container>


  <ng-container *ngIf="status=='success'">
    <div class="confirmation-container">
      <i class="fa-solid fa-check"></i>
      <h2>{{ 'translate_subscription_created_successfully' | translate }}</h2>
      <p>{{ 'translate_you_can_use_account_now' | translate }}</p>
      <div class="buttons">
        <button class="view-order" (click)="goToInvoicesPage()">{{ 'translate_view_my_invoice' | translate }} {{ countdown }} ...</button>
        <button class="view-order" (click)="redirectToGlamera()">{{ 'translate_go_to_glamera' | translate }}</button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="status=='error' || paymentstatus=='error'">
    <div class="confirmation-container">
      <i style="color: red;" class="fa-solid fa-x"></i>
      <h2 style="color: red;">{{ 'Error' | translate }}</h2>
      <p>{{message}}</p>
      <p class="p-0 mb-2 font-md">
        <span class="opacity-50">{{'PaymentReference' | translate}} : </span>
        <span class="opacity-100"> {{paymentRefernce}}</span>
      </p>
      <div class="buttons">
        <button class="view-order" (click)="goToSubscriptions()">{{ 'RetryPayment' | translate }}</button>
      </div>
    </div>
  </ng-container>

</div>