<div class="flex pt-8 align-items-end">
  <div class="flex-1">
    <div class="font-semibold text-2xl mb-2">{{ 'translate_subscription' | translate }}</div>
    <div class="text-xs text-gray-400">
      {{ 'translate_subscription_subtitle' | translate }}
    </div>
  </div>
  <div class="border border-gray-300 rounded-lg p-1">
    <div *ngFor="let link of subLinks" [routerLink]="link.path"
      class="py-2 px-7 rounded inline-block cursor-pointer text-gray-500" routerLinkActive="bg-gray-200 text-black">
      {{ link.name | translate }}
    </div>
  </div>
</div>
<div class="py-10">
  <router-outlet></router-outlet>
</div>