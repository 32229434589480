import { loginStatus } from './../../../../../../glamera-booking-link/src/app/site/feature-modules/login/enum';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaymentMethod } from '@s-app/common/models';
import { PaymentTypePipe } from '@s-app/core/pipes';
import { environment } from 'environments/environment';
import { GlameraSharedService } from 'glamera-shared';
import { Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PaymentCardService {

  private companyId = 0;
  private token ="" ;
  constructor(private http: HttpClient ,
    private sharedService: GlameraSharedService 
  ) {
    this.companyId=sharedService.company.id;
    this.token= sharedService.token ?? "";
   }

  public list(
    isLite: boolean = true,
    isPaginated: boolean = false,
    page: number = 0,
    limit: number = 10,
    search: string = ''
  ): Observable<any> {
    const params = new HttpParams()
      .append('PagingEnabled', isPaginated)
      .append('PageNumber', page)
      .append('PageSize', limit)
      .append('FreeText', search);
    return this.http
      .get<any>(
        `${environment.apiUrl}/SubscriptionCard/${isLite ? 'GetLite' : 'Get'}`, 
        { params }
      )
      .pipe(
        tap((response: any) => {
          response?.SubscriptionCards.forEach((method: PaymentMethod) => {
            this.prepareMethod(method);
          });
          response?.SubscriptionCards.forEach((method: PaymentMethod) => {
            this.prepareMethod(method);
          });
        })
      );
  }

  public get(id: number): Observable<any> {
    const params = new HttpParams().append('Id', id);
    return this.http
      .get<any>(`${environment.apiUrl}/SubscriptionCard/GetById`, { params })
      .pipe(
        tap((response: any) => {
          this.prepareMethod(response?.Result as PaymentMethod);
        })
      );
  }

public create(data: any): Observable<any> {
    console.log(data);
   // Create a temporary object with the required names
    const tempData = {
        ...data,
        ExpiryMonth: parseInt(data.ExpireMonth),
        ExpiryYear: parseInt(data.ExpireYear)
    };
 

    return this.http.post<any>(
      `${environment.apiUrl}/erp/web/company/${this.companyId}/subscription/cards`,
      tempData
    );
}
  public update(data: any): Observable<any> {
    return this.http.put<any>(
      `${environment.apiUrl}/SubscriptionCard/Update`,
      data
    );
  }
  

  public delete(id: number): Observable<any> {
    const params = new HttpParams().append('Id', id);
    return this.http.delete<any>(
      `${environment.apiUrl}/SubscriptionCard/Delete`,
      { params }
    );
  }

  private prepareMethod(method: PaymentMethod): void {
    method.type = new PaymentTypePipe().transform(method.CardNumber);
    const month =
      method.ExpireMonth > 9 ? method.ExpireMonth : '0' + method.ExpireMonth;
    method.ExpirationDate = `${month}/${method.ExpireYear.toString().slice(
      -2
    )}`;

    // temp values
    // TODO
    method.email = 'Billingemail@gmail.com';
    method.assignedIn = Array(3);
    // return method;
  }
}
