import { Component, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PaymentMethod, PaymentMethodDTO } from '@s-app/common/models';
import { SideDialogService } from '@s-app/common/services';
import { PaymentCardService } from '@s-app/core/services/api';
import { PaymentCardCacheService } from '@s-app/core/services/cache';
import { Subject, takeUntil } from 'rxjs';
import Swal from 'sweetalert2';
import { BillingService } from '@bl-src/app/core/services/billing.service';

@Component({
  templateUrl: './pay.component.html',
})
export class PayComponent implements OnDestroy {
  public sectedMethod: number | null = null;
  public paymentMethods: PaymentMethodDTO[];
  private destroy$: Subject<void> = new Subject<void>();

  public constructor(
    private paymentService: BillingService,
    private sideDialogService: SideDialogService,
    private paymentCardService: PaymentCardService,
    private paymentCardCacheService: PaymentCardCacheService,
    private translateService: TranslateService
  ) {
    paymentCardCacheService.list$
      .pipe(takeUntil(this.destroy$))
      .subscribe((cards: PaymentMethodDTO[]) => {
        this.paymentMethods = cards;
      });
  }

  public showPayDialog(): void {
    this.sideDialogService.showPayDialog();
  }

  public showEditMethodDialog(method: PaymentMethod): void {
    this.sideDialogService.showEditMethodDialog(method);
  }

  public showDifferentMethodDialog(): void {
    this.sideDialogService.showDifferentMethodDialog();
  }

  public hideDialog(): void {
    this.sideDialogService.showDialogSubject.next(false);
  }

  public deleteMethod(method: PaymentMethod): void {
    if (method.Id) {
      Swal.fire({
        icon: 'question',
        iconColor: '#ef4444',
        title: this.translateService.instant('translate_are_you_sure'),
        text: this.translateService.instant(
          'translate_the_card_will_be_deleted'
        ),
        showDenyButton: true,
        confirmButtonText: this.translateService.instant('translate_confirm'),
        denyButtonText: this.translateService.instant('translate_cancel'),
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-danger bg-danger me-2',
          denyButton: 'btn btn-outline-danger',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          if (method.Id) {
            this.paymentCardService.delete(method.Id).subscribe((response) => {
              if (response.Result) {
                this.paymentCardCacheService.refresh();
                Swal.fire({
                  icon: 'success',
                  text: this.translateService.instant(
                    'translate_card_deleted_successfully'
                  ),
                  showConfirmButton: false,
                  position: 'bottom-start',
                  width: '20rem',
                  timer: 1000,
                });
              }
            });
          }
        }
      });
    }
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.destroy$.unsubscribe();
  }

  public sendPayment(): void {
    const paymentData = {
      BillingCode: 'GLAJ4CtqwoDhi9czGy',
      BillingId: null,
      UserId: 13374,
      PaymentMethodEnum: 2,
    };

    this.paymentService.postPayment(paymentData).subscribe(
      (response) => {
        console.log('Payment successful:', response);
        if (response.paymentProviderURL) {
          window.location.href = response.paymentProviderURL;
        }
      },
      (error) => {
        console.error('Payment error:', error);
      }
    );
  }
}
